import { Injectable } from '@angular/core';
import { DomainService as DomainSDKService, ListAllDomainsResponse, SenderType } from '@vendasta/email';
import { GetPendingEmailDomainResponse } from '@vendasta/email/lib/_internal/objects/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, skipWhile, take, tap } from 'rxjs/operators';

@Injectable()
export class DomainCacheService {
  private responses = new Map<string, BehaviorSubject<ListAllDomainsResponse>>();
  constructor(private readonly sdk: DomainSDKService) {}

  listAllDomains(senderType: SenderType, senderID: string): Observable<ListAllDomainsResponse> {
    const key = this.buildCacheKey(senderType, senderID);
    let cachedResponse = this.responses.get(key);
    const b = new BehaviorSubject<ListAllDomainsResponse>(null);
    if (!cachedResponse) {
      this.responses.set(key, b);
      this.sdk
        .listAllDomains(senderType, senderID)
        .pipe(shareReplay(1))
        .subscribe((r) => b.next(r));
      this.responses.set(key, b);
      cachedResponse = b;
    }
    return cachedResponse.asObservable().pipe(skipWhile((a) => !a));
  }

  private refreshDomains(senderType: SenderType, senderID: string): void {
    const key = this.buildCacheKey(senderType, senderID);
    let cachedResponse = this.responses.get(key);
    const b = new BehaviorSubject<ListAllDomainsResponse>(null);
    if (!cachedResponse) {
      cachedResponse = b;
      this.responses.set(key, b);
    }
    this.sdk
      .listAllDomains(senderType, senderID)
      .pipe(shareReplay(1), take(1))
      .subscribe((r) => cachedResponse.next(r));
    return;
  }

  createPendingDomain(
    senderType: SenderType,
    senderID: string,
    domain: string,
  ): Observable<GetPendingEmailDomainResponse> {
    const d = this.sdk
      .createPendingDomain(senderType, senderID, domain)
      .pipe(tap(() => this.refreshDomains(senderType, senderID)));
    return d;
  }

  deletePendingDomain(senderType: SenderType, senderID: string, domain: string): Observable<null> {
    const d = this.sdk
      .deletePendingDomain(senderType, senderID, domain)
      .pipe(tap(() => this.refreshDomains(senderType, senderID)));
    return d;
  }

  private buildCacheKey(senderType: SenderType, senderID: string): string {
    return senderType.toString() + ':' + senderID;
  }
}
