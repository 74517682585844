import { Component, OnInit } from '@angular/core';
import { EmailModule, RecipientStatusEnum, RecipientStatusService, SenderType } from '@vendasta/email';
import { BehaviorSubject, combineLatest, Observable, take } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';

dayjs.extend(utc);

interface RecipientStatus {
  sender: string;
  email: string;
  status: string;
  infractionCount?: number;
  bounceExpiry?: Date;
}

const PAGE_SIZE = 500;

@Component({
  selector: 'app-recipient-status',
  standalone: true,
  imports: [
    CommonModule,
    EmailModule,
    GalaxyFormFieldModule,
    FormsModule,
    MatSelectModule,
    MatOptionModule,
    MatDividerModule,
    ClipboardModule,
    MatButtonModule,
    MatCardModule,
  ],
  templateUrl: './recipient-status.component.html',
  styleUrls: ['./recipient-status.component.scss'],
})
export class RecipientStatusComponent implements OnInit {
  public senderType: string;
  public senderID: string;
  public emails: string;

  public statuses$: BehaviorSubject<RecipientStatus[]> = new BehaviorSubject<RecipientStatus[]>([]);
  public resultsCSVString$: Observable<string>;

  constructor(private recipientStatusService: RecipientStatusService) {}

  ngOnInit() {
    this.senderType = '';
    this.resultsCSVString$ = this.statuses$.pipe(
      startWith([]),
      map((statuses) => {
        return (
          'sender,email,status,infractions,expiry\n' +
          statuses
            .map(
              (status) =>
                `${status.sender},${status.email},${status.status},${status.infractionCount},${status.bounceExpiry}`,
            )
            .join('\n')
        );
      }),
    );
  }

  search(): void {
    const emailList = this.emails
      .split('\n')
      .map((line) => line.split(',').map((email) => email.trim()))
      .reduce((acc, val) => acc.concat(val), [])
      .filter((email) => email.length > 0);
    let senderTypeProto = null;
    if (this.senderType === 'partner') {
      senderTypeProto = SenderType.SENDER_TYPE_PARTNER;
    } else if (this.senderType === 'business') {
      senderTypeProto = SenderType.SENDER_TYPE_BUSINESS;
    } else senderTypeProto = SenderType.SENDER_TYPE_INVALID;
    const resultStreams: Observable<RecipientStatus[]>[] = [];
    for (let i = 0; i < emailList.length; i = i + PAGE_SIZE) {
      const emailListSlice = emailList.slice(i, i + PAGE_SIZE);
      resultStreams.push(
        this.recipientStatusService.searchRecipientStatus(senderTypeProto, this.senderID, emailListSlice).pipe(
          take(1),
          map((response) => {
            return response.recipientStatuses.map((recipientStatus) => {
              let statusString = '';
              switch (recipientStatus.status) {
                case RecipientStatusEnum.RECIPIENT_STATUS_INVALID:
                  statusString = 'Invalid';
                  break;
                case RecipientStatusEnum.RECIPIENT_STATUS_HARD:
                  statusString = 'Hard';
                  break;
                case RecipientStatusEnum.RECIPIENT_STATUS_SOFT:
                  statusString = 'Soft';
                  break;
                case RecipientStatusEnum.RECIPIENT_STATUS_UNKNOWN:
                  statusString = 'Unknown';
                  break;
                case RecipientStatusEnum.RECIPIENT_STATUS_RESET:
                  statusString = 'Reset';
                  break;
                case RecipientStatusEnum.RECIPIENT_STATUS_NOT_BLOCKED:
                  statusString = 'Not Blocked';
                  break;
              }
              return {
                sender: recipientStatus.sender?.id || 'Global',
                // a missing sender means there are no current blocks and therefore
                // the recipient is not blocked in a "global" sense
                email: recipientStatus.recipientEmail,
                status: statusString,
                bounceExpiry: recipientStatus.bounceExpiry,
                infractionCount: recipientStatus.infractionCount,
              };
            });
          }),
        ),
      );
    }
    combineLatest(resultStreams).subscribe((results) => {
      this.statuses$.next(results.reduce((acc, val) => acc.concat(val), []));
    });
  }

  downloadCSV(content: string): void {
    const currentDate = dayjs().utc();
    const a = document.createElement('a');
    const mimeType = 'application/octet-stream';

    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      }),
    );
    a.setAttribute('download', 'recipientStatuses-' + currentDate.format('YYYY-MM-DD') + '.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
