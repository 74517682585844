<ng-container *ngIf="verifyResult$ | glxyAsyncStatus | async as result">
  <ng-container [ngSwitch]="result.status">
    <ng-container *ngSwitchCase="'loading'">
      <glxy-loading-spinner [size]="'small'" [fullWidth]="false"></glxy-loading-spinner>
    </ng-container>
    <ng-container *ngSwitchCase="'error'">
      <mat-icon>warning</mat-icon>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="status-and-domain" (click)="handleClick(result.value)">
        <email-domain-status-icon [result]="result.value"></email-domain-status-icon>
        {{ domain.sendFromDomain }}
      </div>
    </ng-container>
  </ng-container>
</ng-container>
