<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>Recipient status</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <glxy-form-field>
      <mat-select [(ngModel)]="senderType" placeholder="Sender type">
        <mat-option [value]="''">None</mat-option>
        <mat-option [value]="'partner'">Partner</mat-option>
        <mat-option [value]="'business'">Business</mat-option>
      </mat-select>
    </glxy-form-field>
    <glxy-form-field>
      <input matInput placeholder="Sender ID" name="senderName" [(ngModel)]="senderID" />
    </glxy-form-field>
    <glxy-form-field>
      <textarea matInput placeholder="Recipient emails" name="recipientEmails" [(ngModel)]="emails" required></textarea>
    </glxy-form-field>
  </mat-card-content>
  <mat-card-actions *ngIf="resultsCSVString$ | async as results">
    <button mat-flat-button color="primary" (click)="search()">Search</button>
    <button mat-flat-button [disabled]="(statuses$ | async)?.length === 0" [cdkCopyToClipboard]="results">
      Copy results as CSV to clipboard
    </button>
    <button mat-flat-button [disabled]="(statuses$ | async)?.length === 0" (click)="downloadCSV(results)">
      Download results as CSV
    </button>
  </mat-card-actions>
  <mat-divider />
  <ng-container *ngIf="(statuses$ | async)?.length !== 0">
    <mat-card-header>
      <mat-card-title>Results</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="results">
        <div *ngFor="let s of statuses$ | async">
          {{ s.sender }} - {{ s.email }}: {{ s.status }}
          <span *ngIf="s.infractionCount">- {{ s.infractionCount }}</span>
          <span *ngIf="s.bounceExpiry">- {{ s.bounceExpiry | date: 'MMM d, y, h:mm:ss a' }}</span>
        </div>
      </div>
    </mat-card-content>
  </ng-container>
</mat-card>
