export const UNSAVED_DATA_HANDLER_TOKEN = 'com.vendasta.email-ui.unsaved_data_handler';

export enum SavedState {
  SAFE_TO_NAVIGATE_AWAY = 'safe',
  HAS_UNSAVED_CHANGES = 'unsaved',
}

export interface UnsavedDataHandler {
  notifyStateChanged(newState: SavedState): void;
}
