import { AfterViewInit, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ListAllDomainsResponse, SenderDomain, SenderType } from '@vendasta/email';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { Observable, firstValueFrom } from 'rxjs';
import { map, skipWhile, tap } from 'rxjs/operators';
import { DomainCacheService } from './domain-cache.service';
import { DomainsComponent } from './domains/domains.component';
import {
  NewDomainDialogComponent,
  NewDomainDialogData,
  Result as NewDomainDialogResult,
} from './new-domain-dialog/new-domain-dialog.component';
@Component({
  selector: 'email-domain-authorization',
  templateUrl: './domain-authorization.component.html',
  styleUrls: ['./domain-authorization.component.scss'],
})
export class DomainAuthorizationComponent implements AfterViewInit, OnChanges {
  @Input() senderType: SenderType;
  @Input() senderID: string;

  @ViewChild(DomainsComponent) domainsComponent: DomainsComponent;

  allDomains$: Observable<SenderDomain[] | boolean>;
  deleting: boolean;

  constructor(
    private readonly domainService: DomainCacheService,
    private readonly dialog: MatDialog,
    private readonly snack: SnackbarService,
  ) {}

  ngAfterViewInit(): void {
    this.refreshDomains();
  }

  ngOnChanges(): void {
    this.refreshDomains();
  }

  private refreshDomains(andScrollToDomain?: string): void {
    if (!this.senderID || this.senderType === SenderType.SENDER_TYPE_INVALID) {
      return;
    }
    // We replace the entire observable because errors cause the original observable to complete
    this.allDomains$ = this.domainService.listAllDomains(this.senderType, this.senderID).pipe(
      skipWhile((v) => !v),
      map((v: ListAllDomainsResponse) => v.domains),
      tap(() => window.setTimeout(() => (andScrollToDomain ? this.scrollToDomain(andScrollToDomain) : undefined), 0)),
    );
  }

  async handleAuthorizationRequested(): Promise<void> {
    const data: NewDomainDialogData = {
      senderID: {
        senderType: this.senderType,
        senderID: this.senderID,
      },
    };
    const dialog = this.dialog.open(NewDomainDialogComponent, { data: data });
    const result: NewDomainDialogResult = await firstValueFrom(dialog.afterClosed());

    if (result.newPendingDomain) {
      this.refreshDomains(result.newPendingDomain);
    }
  }

  scrollToDomain(domain: string): void {
    this.domainsComponent.scrollToDomain(domain);
  }

  async handleDeleteRequested(domain: string): Promise<void> {
    const delete$ = this.domainService.deletePendingDomain(this.senderType, this.senderID, domain);
    this.deleting = true;
    await firstValueFrom(delete$)
      .then(() => {
        this.snack.openSuccessSnack('Domain deleted');
        this.refreshDomains();
      })
      .catch(() => this.snack.openErrorSnack('There was a problem deleting the domain. Try again.'))
      .finally(() => (this.deleting = false));
  }
}
