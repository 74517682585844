import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomainInfoDialogComponent } from './domain-info-dialog.component';
import { DomainInfoCardModule } from '../domain-info-card/domain-info-card.module';

@NgModule({
  declarations: [DomainInfoDialogComponent],
  imports: [CommonModule, DomainInfoCardModule],
})
export class DomainInfoDialogModule {}
