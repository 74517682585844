import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DomainService,
  Sender,
  SenderDomain,
  VerifyDefaultDomainResponse,
  VerifyDefaultDomainResponseInterface,
} from '@vendasta/email';
import { EMPTY, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

export interface Data {
  sender: Sender;
  domain: SenderDomain;
}

@Component({
  selector: 'email-domain-status-and-name',
  templateUrl: './domain-status-and-name.component.html',
  styleUrls: ['./domain-status-and-name.component.scss'],
})
export class DomainStatusAndNameComponent {
  verifyResult$: Observable<VerifyDefaultDomainResponse> = EMPTY;
  domain: SenderDomain;
  private sender: Sender;

  @Output() public readonly domainChangeRequested = new EventEmitter<string>();
  @Output() public readonly invalidDomainChangeRequested = new EventEmitter<string>();
  @Output() public readonly clicked = new EventEmitter<null>();

  @Input()
  public set data(value: Data) {
    this.domain = value.domain;
    this.sender = value.sender;
    if (value.domain.isPrimary) {
      this.verifyResult$ = this.sdk.verifyDefaultDomain(value.sender.type, value.sender.id);
    } else {
      this.verifyResult$ = this.sdk.verifyPendingDomain(value.sender.type, value.sender.id);
    }
  }

  constructor(private readonly sdk: DomainService, private readonly dialog: MatDialog) {}

  public handleClick(result: VerifyDefaultDomainResponseInterface): void {
    this.clicked.emit();
    if (result.allRecordsMatch) {
      this.domainChangeRequested.emit(this.domain.sendFromDomain);
    } else {
      this.invalidDomainChangeRequested.emit(this.domain.sendFromDomain);
    }
  }
}
