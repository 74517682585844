import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmailDataSource } from './email.data-source';
import { EmailPreviewDialogComponent } from './preview/email-preview.component';
import {
  DatePickerFilterField,
  Filters,
  FilterService,
  FilterSection,
  RadioFilterField,
  TextFilterField,
} from '@vendasta/uikit';
import { EmailMessage, EmailService, MessageService } from '@vendasta/email';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { BehaviorSubject, Subscription, firstValueFrom, take } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  emailDataSource: EmailDataSource;
  displayedColumns: string[] = ['timestamp', 'partner', 'subject', 'to', 'from'];

  private searchTerm$$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private searchAfterField = new DatePickerFilterField({
    name: 'Sent After',
    value: '',
  });

  filters: Filters = new Filters('Filters', [
    new FilterSection({
      title: 'Participants',
      type: 'and',
      fields: [
        new TextFilterField({
          name: 'To',
          value: '',
        }),
        new TextFilterField({
          name: 'From',
          value: '',
        }),
      ],
    }),
    new FilterSection({
      title: 'Sender Domain Info',
      type: 'and',
      fields: [
        new TextFilterField({
          name: 'Partner ID',
          value: '',
        }),
        new TextFilterField({
          name: 'Application ID',
          value: '',
        }),
        new TextFilterField({
          name: 'Business ID',
          value: '',
        }),
      ],
    }),
    new FilterSection({
      title: 'Email Type',
      type: 'and',
      fields: [
        new RadioFilterField({
          name: 'Email Service Provider',
          value: '',
          options: [
            {
              id: '',
              name: 'All',
            },
            {
              id: 'ESP_BILLING',
              name: 'Billing',
            },
            {
              id: 'ESP_MARKETING',
              name: 'Marketing',
            },
            {
              id: 'ESP_PRODUCT',
              name: 'Product',
            },
            {
              id: 'ESP_SMB',
              name: 'SMB',
            },
            {
              id: 'ESP_WEBSITE_PRO',
              name: 'Website Pro',
            },
            {
              id: 'ESP_WEBSITE_PRO_TRANSACTIONAL',
              name: 'WSP Transactional',
            },
          ],
        }),
      ],
    }),
    new FilterSection({
      title: 'Sent',
      type: 'and',
      fields: [
        this.searchAfterField,
        new DatePickerFilterField({
          name: 'Sent Before',
          value: '',
        }),
      ],
    }),
  ]);

  getPartnerID(item: EmailMessage): string {
    if (item.attributes) {
      // notification style of key naming is `partnerId`
      const partnerId = item.attributes.find((e) => e.key === 'partnerId');

      // new notification microservice email key is now `partner_id` !!? Why??!
      const partner_id = item.attributes.find((e) => e.key === 'partner_id');

      if (partnerId) {
        return partnerId.value;
      } else if (partner_id) {
        return partner_id.value;
      }
    }
    return null;
  }

  openEmailDialog(email: EmailMessage): void {
    const filters = this.filterService.filters;
    this.dialog
      .open(EmailPreviewDialogComponent, {
        data: email,
        panelClass: 'mat-dialog-no-padding',
      })
      .afterClosed()
      .subscribe(() => this.filterService.setFilters(filters));
  }

  set searchTerm(s: string) {
    this.searchTerm$$.next(s);
  }

  get searchTerm(): string {
    return this.searchTerm$$.getValue();
  }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly messageService: MessageService,
    private readonly alerts: SnackbarService,
    public emailService: EmailService,
    public dialog: MatDialog,
    private snackBarService: SnackbarService,
    private readonly router: Router,
    private readonly filterService: FilterService,
  ) {}

  ngOnInit(): void {
    this.emailDataSource = new EmailDataSource(this.emailService, this.snackBarService);
    this.filterService.setFilters(this.filters);
    this.subscriptions.push(
      this.filterService.fieldValueChanges.subscribe((fields) => {
        fields.forEach((f) => this.emailDataSource.updateFilter(f));
      }),
    );

    if (!this.searchAfterField.value) {
      this.searchAfterField.changeValue(moment().subtract(7, 'days'));
    }

    const emailIdFromURL = this.activatedRoute.snapshot.params['emailId'];
    if (emailIdFromURL) {
      this.loadMessageAndOpenInDialog(emailIdFromURL).catch((err) => {
        console.error('Failed to load message', err);
        this.alerts.errorSnack('Failed to load message');
      });
    }

    this.subscriptions.push(
      this.activatedRoute.queryParams.pipe(take(1)).subscribe((p: Params) => {
        this.searchTerm = p.search_term || '';
      }),
    );

    this.subscriptions.push(
      this.searchTerm$$.subscribe((s) => {
        this.emailDataSource.updateSearchTerm(s);
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { search_term: s },
          queryParamsHandling: 'merge',
        });
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private async loadMessageAndOpenInDialog(emailID: string): Promise<void> {
    return firstValueFrom(this.messageService.getMessage(emailID)).then((result: EmailMessage) =>
      this.openEmailDialog(result),
    );
  }
}
