import { Component, OnInit } from '@angular/core';
import { nicerLoading } from '../ui-utils/async';
import { GLOBAL_INPUT } from '@vendasta/data-providers';
import { map } from 'rxjs/operators';
import { Reputation, SendgridReputationsSource } from '../../../data-providers/email/sendgrid-reputations.source';

function sort(v: Reputation[]): Reputation[] {
  return v.sort((v1, v2) => (v1.reputationPercent > v2.reputationPercent ? 1 : -1));
}

@Component({
  templateUrl: './send-grid-reputation.component.html',
  styleUrls: ['./send-grid-reputation.component.scss'],
})
export class SendGridReputationComponent implements OnInit {
  readonly loading$ = nicerLoading(this.data.state.isLoading$(GLOBAL_INPUT));
  readonly failed$ = this.data.state.isSuccess$(GLOBAL_INPUT).pipe(map((v) => !v));
  readonly data$ = this.data.getCachedOrFetch$(GLOBAL_INPUT).pipe(map(sort));

  constructor(private readonly data: SendgridReputationsSource) {}

  ngOnInit(): void {
    this.data.requestRefresh(GLOBAL_INPUT);
  }

  handleTryAgain(): void {
    this.data.requestRefresh(GLOBAL_INPUT);
  }
}
