import { HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdminService, GetUnsubscribedRecipientResponseInterface } from '@vendasta/email';
import { PopoverPositions } from '@vendasta/galaxy/popover';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { BehaviorSubject, Observable } from 'rxjs';

interface Recipient {
  email: string;
  partnerID: string;
}

type SenderType = '' | 'partner' | 'business' | 'product';

@Component({
  selector: 'app-resubscribe',
  templateUrl: './resubscribe.component.html',
  styleUrls: ['./resubscribe.component.scss'],
})
export class ResubscribeComponent {
  senderType: SenderType = 'partner';
  isHelpPopoverOpen: boolean;
  searchResult$$: BehaviorSubject<Recipient> = new BehaviorSubject<Recipient>(null);
  searchForm: UntypedFormGroup;
  loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  pos = PopoverPositions['TopRight'];

  constructor(
    formBuilder: UntypedFormBuilder,
    private adminService: AdminService,
    private alertService: SnackbarService,
  ) {
    this.searchForm = formBuilder.group({
      email: ['', Validators.required],
      partnerId: ['', Validators.required],
      consent: [false, Validators.required],
    });
  }

  searchForUnsubscribed(): void {
    this.loading$$.next(true);
    this.searchForm.get('consent').setValue(false);
    const partnerID = this.searchForm.get('partnerId').value;
    const recipientEmail = this.searchForm.get('email').value;
    let get$: Observable<GetUnsubscribedRecipientResponseInterface>;
    switch (this.senderType) {
      case '':
        throw new Error('Cannot get unsubscribed for empty sender type');
      case 'business':
        get$ = this.adminService.getUnsubscribedRecipientForBusiness(recipientEmail);
        break;
      case 'partner':
        get$ = this.adminService.getUnsubscribedRecipientForPartner(partnerID, recipientEmail);
        break;
      case 'product':
        get$ = this.adminService.getUnsubscribedRecipientForProduct(recipientEmail);
        break;
    }
    get$.subscribe(
      (response: GetUnsubscribedRecipientResponseInterface) => {
        this.searchResult$$.next({ email: response.recipientEmail, partnerID: partnerID });
        this.loading$$.next(false);
      },
      (error) => {
        this.loading$$.next(false);
        if (error.status === 404) {
          this.alertService.errorSnack('Recipient not found');
        } else if (error.status === 400) {
          this.alertService.errorSnack('Search failed');
          console.error('search failed', error.error.message);
        } else {
          this.alertService.errorSnack('Search failed');
        }
      },
    );
  }

  resubscribeRecipient(recipient: Recipient): void {
    this.loading$$.next(true);

    let resub$: Observable<HttpResponse<null>>;
    switch (this.senderType) {
      case '':
        throw new Error('Cannot resubscribe for empty sender type');
      case 'business':
        resub$ = this.adminService.resubscribeRecipientForBusiness(recipient.email);
        break;
      case 'partner':
        resub$ = this.adminService.resubscribeRecipientForPartner(recipient.partnerID, recipient.email);
        break;
      case 'product':
        resub$ = this.adminService.resubscribeRecipientForProduct(recipient.email);
        break;
    }
    resub$.subscribe({
      next: () => {
        this.alertService.successSnack('Recipient resubscribed');
        this.searchResult$$.next(null);
        this.searchForm.get('email').setValue('');
        this.searchForm.get('partnerId').setValue('');
        this.loading$$.next(false);
      },
      error: (error) => {
        this.loading$$.next(false);
        if (error.status === 404) {
          this.alertService.errorSnack('Failed to resubscribe, recipient not found');
        } else {
          this.alertService.errorSnack('Failed to resubscribe');
          console.error('failed to resubscribe', error?.error?.message);
        }
      },
    });
  }

  goBack(): void {
    this.searchResult$$.next(null);
    this.searchForm.get('email').setValue('');
    this.searchForm.get('partnerId').setValue('');
  }

  openHelpNote(): void {
    this.isHelpPopoverOpen = true;
  }

  closeHelpNote(): void {
    this.isHelpPopoverOpen = false;
  }
}
