import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SenderInfoCardComponent } from './sender-info-card.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { GalaxyWrapModule } from '@vendasta/galaxy/galaxy-wrap';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { GalaxyInputModule } from '@vendasta/galaxy/input';
import { MatIconModule } from '@angular/material/icon';
import { EmailUsernameInputModule } from '../../../email-username-input/email-username-input.module';
import { SendersService } from '@vendasta/email';
import { GalaxyPipesModule } from '@vendasta/galaxy/pipes';
import { GalaxyLoadingSpinnerModule } from '@vendasta/galaxy/loading-spinner';
import { GalaxyEmptyStateModule } from '@vendasta/galaxy/empty-state';
import { SenderInfoFormComponent } from './sender-info-form/sender-info-form.component';
import { MatButtonModule } from '@angular/material/button';
import { GalaxyTooltipModule } from '@vendasta/galaxy/tooltip';
import { GalaxyButtonLoadingIndicatorModule } from '@vendasta/galaxy/button-loading-indicator';
import { DomainsDialogModule } from '../domains-dialog/domains-dialog.module';
import { EmailUII18nModule } from '../../../src/lib/i18n/email-ui-i18n.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { GalaxyAlertModule } from '@vendasta/galaxy/alert';
import { EmailForwardingInfoComponent } from './sender-info-form/email-forwarding-info/email-forwarding-info.component';

@NgModule({
  declarations: [SenderInfoCardComponent, SenderInfoFormComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatCardModule,
    ReactiveFormsModule,
    MatInputModule,
    GalaxyWrapModule,
    GalaxyFormFieldModule,
    MatRadioModule,
    FormsModule,
    GalaxyInputModule,
    MatIconModule,
    EmailUsernameInputModule,
    GalaxyPipesModule,
    GalaxyLoadingSpinnerModule,
    GalaxyEmptyStateModule,
    MatButtonModule,
    GalaxyTooltipModule,
    GalaxyButtonLoadingIndicatorModule,
    DomainsDialogModule,
    EmailUII18nModule,
    TranslateModule,
    GalaxyAlertModule,
    EmailForwardingInfoComponent,
  ],
  exports: [SenderInfoCardComponent],
  providers: [SendersService],
})
export class SenderInfoCardModule {}
