import { Injectable } from '@angular/core';
import { DataProviderConfig, KeyedDataProvider } from '@vendasta/data-providers';
import { ListSendGridTeammatesResponseInterface, SendgridTeammatesService } from '@vendasta/email';
import { Observable, of, throwError } from 'rxjs';

export interface SendGridAdmin {
  name: string;
  email: string;
  role?: string;
  slackName?: string;
}

type EmailResponse = ListSendGridTeammatesResponseInterface;

@Injectable({
  providedIn: 'root',
})
export class SendgridAdminsSource extends KeyedDataProvider<string, EmailResponse, SendGridAdmin[]> {
  constructor(svc: SendgridTeammatesService) {
    const cfg: DataProviderConfig<string, EmailResponse, SendGridAdmin[]> = {
      externalSource: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fetchFn: (i: string) => svc.list(),
        uploadFn: () => throwError('Upload not supported'),
      },
      convert: {
        apiDataToAppData: emailResponseToUIObject,
        inputsToStringKey: (i) => i,
      },
    };
    super(cfg);
  }
}

function emailResponseToUIObject(a: EmailResponse): Observable<SendGridAdmin[]> {
  const sga: SendGridAdmin[] = a.teammates.map((v) => ({
    name: v.fullName,
    email: v.email,
    role: v.role,
    slackName: v.slackUserName,
    slackUrl: v.slackUrl,
  }));
  return of(sga);
}
