import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DomainService,
  GetSenderInfoResponse,
  GetSenderInfoResponseInterface,
  Sender,
  SendersService,
} from '@vendasta/email';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SavedState, UNSAVED_DATA_HANDLER_TOKEN, UnsavedDataHandler } from './dependencies';
import { SenderInfoFormComponent } from './sender-info-form/sender-info-form.component';

@Component({
  selector: 'email-sender-info-card',
  templateUrl: './sender-info-card.component.html',
  styleUrls: ['./sender-info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SenderInfoCardComponent implements OnChanges {
  @Output() authorizeRequested: EventEmitter<void> = new EventEmitter<void>();
  @Output() invalidDomainChangeRequested = new EventEmitter<string>();
  @Input() sender: Sender;
  @ViewChild(SenderInfoFormComponent) form: SenderInfoFormComponent;

  senderInfo$: Observable<GetSenderInfoResponseInterface>;
  domainOverride: string | null = null;

  constructor(
    private readonly api: SendersService,
    private readonly domains: DomainService,
    private readonly alerts: SnackbarService,
    @Inject(UNSAVED_DATA_HANDLER_TOKEN) private readonly unsavedHandler: UnsavedDataHandler,
  ) {}

  ngOnChanges(): void {
    this.reInitializeObservables();
  }

  reInitializeObservables(): void {
    this.domainOverride = null;
    this.senderInfo$ = this.api.getEmailSendingInfo(this.sender.type, this.sender.id).pipe(
      tap((v) => this.auditDomain(v)),
      map((res) => {
        if (Object.keys(res).length === 0) {
          return {
            isDefaultForSenderType: true,
          } as GetSenderInfoResponseInterface;
        }
        return res;
      }),
      catchError((e) => {
        if (e.status === 404) {
          return of({
            isDefaultForSenderType: true,
          } as GetSenderInfoResponseInterface);
        }
        this.alerts.openErrorSnack('DOMAIN_AUTHORIZATION.SENDER_INFO_CARD.LOADING_ERROR');
        return EMPTY;
      }),
    );
    this.unsavedHandler.notifyStateChanged(SavedState.SAFE_TO_NAVIGATE_AWAY);
  }

  handleAuthorizeRequested(): void {
    this.authorizeRequested.emit();
  }

  setDomain(domain: string): void {
    if (this.domainOverride !== domain) {
      this.alerts.openSuccessSnack('DOMAIN_AUTHORIZATION.SENDER_INFO_CARD.DOMAIN_SELECT_SUCCESS');
      this.unsavedHandler.notifyStateChanged(SavedState.HAS_UNSAVED_CHANGES);
    }
    this.domainOverride = domain;
  }

  private auditDomain(v: GetSenderInfoResponse): void {
    if (!v.domain) {
      // Partner has an empty sender domain. Try to keep user on page to fix it.
      this.unsavedHandler.notifyStateChanged(SavedState.HAS_UNSAVED_CHANGES);
    }
  }
}
