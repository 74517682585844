import { Component, Input } from '@angular/core';
import { SendGridAdmin } from '../../../../../data-providers';

@Component({
  selector: 'app-sendgrid-admins-table',
  templateUrl: './sendgrid-admins-table.component.html',
  styleUrls: ['./sendgrid-admins-table.component.scss'],
})
export class SendgridAdminsTableComponent {
  displayedColumns: string[] = ['name', 'email', 'role', 'slack'];

  @Input() dataSource: SendGridAdmin[];
}
