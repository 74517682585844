import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailUsernameInputComponent } from './email-username-input.component';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { GalaxyTooltipModule } from '@vendasta/galaxy/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { EmailUII18nModule } from '../src/lib/i18n/email-ui-i18n.module';

@NgModule({
  declarations: [EmailUsernameInputComponent],
  imports: [
    CommonModule, //
    GalaxyFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    GalaxyTooltipModule,
    TranslateModule,
    EmailUII18nModule,
  ],
  exports: [EmailUsernameInputComponent],
})
export class EmailUsernameInputModule {}
