import { Component, Input } from '@angular/core';
import {
  GetPendingEmailDomainTargetCNameRecordsInterface,
  VerifyPendingEmailDomainResponseInterface,
} from '@vendasta/email';

export interface DomainStatus {
  targetSpf?: string;
  currentSpf?: string;
  targets?: GetPendingEmailDomainTargetCNameRecordsInterface;
}

@Component({
  selector: 'email-domain-status-icon',
  templateUrl: './domain-status-icon.component.html',
  styleUrls: ['./domain-status-icon.component.scss'],
})
export class DomainStatusIconComponent {
  @Input() result: VerifyPendingEmailDomainResponseInterface;
}
