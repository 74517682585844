<email-sender-info-card
  #infocard
  [sender]="{ type: senderType, id: senderID }"
  (authorizeRequested)="handleAuthorizationRequested()"
  (invalidDomainChangeRequested)="scrollToDomain($event)"
></email-sender-info-card>

<div class="domains-header">
  <h3>{{ 'DOMAIN_AUTHORIZATION.GENERAL.HEADER' | translate }}</h3>
  <div>
    {{ 'DOMAIN_AUTHORIZATION.GENERAL.PURPOSE' | translate }}
  </div>
</div>

<ng-container *ngIf="allDomains$ | glxyAsyncStatus | async as domains" [ngSwitch]="domains.status">
  <!-- Begin state handling -->
  <ng-container *ngSwitchCase="'loaded'">
    <email-domains
      [senderId]="{ senderType: senderType, senderID: senderID }"
      [domains]="domains.value"
      [deleteInProgress]="deleting"
      (deleteRequested)="handleDeleteRequested($event)"
      (authorizeRequested)="handleAuthorizationRequested()"
      (useAsDefaultRequested)="infocard.setDomain($event)"
    ></email-domains>
  </ng-container>

  <ng-container *ngSwitchCase="'empty'">
    <email-domains
      [senderId]="{ senderType: senderType, senderID: senderID }"
      [domains]="[]"
      [deleteInProgress]="deleting"
      (deleteRequested)="handleDeleteRequested($event)"
      (authorizeRequested)="handleAuthorizationRequested()"
      (useAsDefaultRequested)="infocard.setDomain($event)"
    ></email-domains>
  </ng-container>

  <ng-container *ngSwitchCase="'loading'">
    <glxy-loading-spinner></glxy-loading-spinner>
  </ng-container>

  <ng-container *ngSwitchCase="'error'">
    <ng-container *ngTemplateOutlet="error"></ng-container>
  </ng-container>
  <!-- End state handling -->

  <ng-template #error>
    <glxy-empty-state [size]="'small'">
      <glxy-empty-state-hero>
        <mat-icon color="warn">error</mat-icon>
      </glxy-empty-state-hero>
      <glxy-empty-state-title>{{ 'DOMAIN_AUTHORIZATION.GENERAL.ERROR' | translate }}</glxy-empty-state-title>
      <p>{{ domains.error }}</p>
    </glxy-empty-state>
  </ng-template>
</ng-container>
