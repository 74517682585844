import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { AdminService, BounceSender, BounceSenderType, GetBouncedRecipientResponseInterface } from '@vendasta/email';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { BehaviorSubject } from 'rxjs';

interface Recipient {
  email: string;
  senderId: string;
}

type Type = 'partner' | 'business' | 'yesware' | '';

@Component({
  selector: 'app-unbounce',
  templateUrl: './unbounce.component.html',
  styleUrls: ['./unbounce.component.scss'],
})
export class UnbounceComponent {
  senderType: Type = 'partner';
  searchResult$$: BehaviorSubject<Recipient> = new BehaviorSubject<Recipient>(null);
  searchForm: UntypedFormGroup;
  loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    formBuilder: UntypedFormBuilder,
    private alertService: SnackbarService,
    private adminService: AdminService,
  ) {
    this.searchForm = formBuilder.group({
      email: ['', Validators.required],
      senderId: ['', Validators.required],
    });
  }

  searchForBounced(): void {
    const senderId = this.searchForm.get('senderId').value;
    const recipientEmail = this.searchForm.get('email').value;

    if (senderId === '' || recipientEmail === '') {
      this.alertService.openErrorSnack('Please enter the required fields');
    } else if (recipientEmail.length < 2) {
      this.alertService.openErrorSnack('Please enter a valid email address');
    } else {
      this.loading$$.next(true);
      const sender = this.getSender(senderId);

      const get$ = this.adminService.getBouncedRecipient(sender, recipientEmail);

      get$.subscribe(
        (response: GetBouncedRecipientResponseInterface) => {
          this.searchResult$$.next({ email: response.recipientEmail, senderId: senderId });
          this.loading$$.next(false);
        },
        (error) => {
          this.loading$$.next(false);
          if (error.status === 404) {
            this.alertService.openErrorSnack('Recipient not found');
          } else {
            this.alertService.openErrorSnack('Search failed');
            console.error('search failed', error.error.message);
          }
        },
      );
    }
  }

  unbounceRecipient(recipient: Recipient): void {
    this.loading$$.next(true);
    const sender = this.getSender(recipient.senderId);
    const unbounce$ = this.adminService.unbounceRecipient(sender, recipient.email);

    unbounce$.subscribe({
      next: () => {
        this.alertService.openSuccessSnack('Recipient unbounced');
        this.goBack();
        this.loading$$.next(false);
      },
      error: (error) => {
        this.loading$$.next(false);
        if (error.status === 404) {
          this.alertService.openErrorSnack('Failed to unbounce, recipient not found');
        } else {
          this.alertService.openErrorSnack('Failed to unbounce');
          console.error('failed to unbounce', error.error.message);
        }
      },
    });
  }

  goBack(): void {
    this.searchResult$$.next(null);
    this.searchForm.get('email').setValue('');
    this.searchForm.get('senderId').setValue(this.senderType == 'yesware' ? '~' : '');
  }

  typeChanged(typ: MatSelectChange): void {
    if (typ.value == 'yesware') {
      // The API (and form validation) requires a value, but it's not important for Yesware
      this.searchForm.get('senderId').setValue('~');
    }
  }

  getSender(senderID: string): BounceSender {
    let type = BounceSenderType.UNBOUNCE_SENDER_TYPE_PARTNER;
    if (this.senderType === 'business') {
      type = BounceSenderType.UNBOUNCE_SENDER_TYPE_BUSINESS;
    }
    if (this.senderType === 'yesware') {
      type = BounceSenderType.UNBOUNCE_SENDER_TYPE_YESWARE;
    }
    return new BounceSender({ type, id: senderID });
  }
}
