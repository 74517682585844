import { Observable, of, SchedulerLike } from 'rxjs';
import {
  ObservableWorkStateMap,
  WorkStateMap,
  applyFinishedWork,
  startWorkIfNeededForSubscriber,
} from '@vendasta/rx-utils/work-state';
import { map, shareReplay, switchMap } from 'rxjs/operators';

export function IDENTITY<DATA>(data: DATA): Observable<DATA> {
  return of(data);
}

export const GLOBAL_INPUT = 'global';

/**
 * External source is the origin of the data. This might be a remote HTTP
 * server or an in-memory service that performs expensive computations.
 */
interface ExternalSource<INPUTS, API_DATA, DATA> {
  /**
   * fetchFn acquires a new copy of the data using the given inputs.
   * The data could come from an HTTP request or an expensive async procedure.
   */
  fetchFn: (i: INPUTS) => Observable<API_DATA>;
  /**
   * uploadFn sends the provided data to the external source. It also stores
   * the provided data locally, overwriting any existing data previously
   * computed/fetched/set for the same set of inputs.
   */
  uploadFn: (i: INPUTS, d: DATA) => Observable<null>;
}

interface Testing {
  scheduler?: SchedulerLike;
}

/**
 * Defines how the KeyedDataProvider should acquire data and surface it back to
 * the caller.
 */
export interface DataProviderConfig<INPUTS, API_DATA, DATA> {
  /**
   * externalSource is the origin of the data. This might be a remote HTTP
   * server or an in-memory service that performs expensive computations.
   */
  externalSource: ExternalSource<INPUTS, API_DATA, DATA>;
  /**
   * convert is a set of functions for transforming data for different use cases
   */
  convert: {
    /**
     * apiDataToAppData is a function that converts from the API's response
     * into an object that can be used for display purposes. It returns an
     * observable in case additional async work is required to produce the
     * final object. For example, an additional HTTP request to acquire display
     * data.
     */
    apiDataToAppData: (API_DATA) => Observable<DATA>;
    /**
     * inputsToStringKey is a function that converts a set of inputs into a
     * key string in a reproducable way. This key will be used for providing
     * access to loading, error, and success values when multiple sets of
     * inputs have been processed by the Data Provider.
     */
    inputsToStringKey: (INPUTS) => string;
  };
  testing?: Testing;
}

export class KeyedDataProvider<INPUTS, API_DATA, DATA> {
  private readonly stateMap = new ObservableWorkStateMap<string, DATA>();
  readonly state: WorkStateMap<string, DATA> = this.stateMap;
  private readonly updateStateMap = new ObservableWorkStateMap<string, null>();

  constructor(private readonly config: DataProviderConfig<INPUTS, API_DATA, DATA>) {}

  getCachedOrFetch$(param: INPUTS): Observable<DATA> {
    const loadDataFn = this.getWorkFn(param);
    const key = this.config.convert.inputsToStringKey(param);
    const scheduler = this.config.testing?.scheduler || undefined;
    return startWorkIfNeededForSubscriber(key, this.stateMap, loadDataFn, scheduler);
  }

  requestRefresh(param: INPUTS): void {
    const work = this.getWorkFn(param);
    const key = this.config.convert.inputsToStringKey(param);
    this.stateMap.startWork(key, work());
  }

  private getWorkFn(param: INPUTS): () => Observable<DATA> {
    return () =>
      this.config.externalSource
        .fetchFn(param)
        .pipe(switchMap((v: API_DATA) => this.config.convert.apiDataToAppData(v)));
  }

  store(i: INPUTS, data: DATA): void {
    const work = this.config.externalSource.uploadFn(i, data).pipe(shareReplay(1));
    const key = this.config.convert.inputsToStringKey(i);
    applyFinishedWork(this.stateMap, key, data);
    this.updateStateMap.startWork(key, work);
  }

  storeStateSuccess$(i: INPUTS): Observable<boolean> {
    return this.updateStateMap.isSuccess$(this.config.convert.inputsToStringKey(i));
  }

  isLoading$(i: INPUTS): Observable<boolean> {
    return this.state.isLoading$(this.config.convert.inputsToStringKey(i));
  }

  isSuccess$(i: INPUTS): Observable<boolean> {
    return this.state.isSuccess$(this.config.convert.inputsToStringKey(i));
  }

  isFailed$(i: INPUTS): Observable<boolean> {
    return this.isSuccess$(i).pipe(map((v) => !v));
  }
}
