<glxy-page>
  <glxy-page-toolbar>
    <glxy-page-nav>
      <glxy-page-nav-button previousPageTitle="Previous Page" previousPageUrl="/sendgrid" />
    </glxy-page-nav>
    <glxy-page-title>SendGrid Reputation</glxy-page-title>
  </glxy-page-toolbar>
  <glxy-page-wrapper>
    <glxy-alert type="tip">Here you can see the current SendGrid sender reputation for our sub-users</glxy-alert>
    <mat-card appearance="outlined">
      <uikit-async-ui [data]="data$ | async" [loading]="loading$ | async" [error]="failed$ | async">
        <ng-container *successData="let reputations">
          <ng-container *ngFor="let rep of reputations">
            <mat-card appearance="outlined">
              <mat-progress-bar [value]="rep.reputationPercent" [color]="rep.color" />
              <div id="progress-text">{{ rep.reputationPercent }}</div>
              <div>{{ rep.username }}</div>
            </mat-card>
          </ng-container>
        </ng-container>
        <ng-container error *ngTemplateOutlet="errorTemplate" />
      </uikit-async-ui>
    </mat-card>
  </glxy-page-wrapper>
</glxy-page>

<ng-template #errorTemplate>
  <glxy-empty-state size="small">
    <glxy-empty-state-hero>
      <mat-icon>error_outline</mat-icon>
    </glxy-empty-state-hero>
    <glxy-empty-state-title>Unable to load Reputations</glxy-empty-state-title>
    <glxy-empty-state-actions>
      <button mat-raised-button (click)="handleTryAgain()">Try Again</button>
    </glxy-empty-state-actions>
  </glxy-empty-state>
</ng-template>
