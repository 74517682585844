import { Component, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { SenderID, ExtendedDomain } from '../shared-types';
import { DomainInfoCardComponent } from '../domain-info-card/domain-info-card.component';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';

@Component({
  selector: 'email-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.scss'],
})
export class DomainsComponent {
  @Input() readonly senderId: SenderID;
  @Input() readonly domains: ExtendedDomain[] = [];
  @Input() readonly deleteInProgress = false;
  @Input() readonly showEmpty = true;

  @Output() readonly deleteRequested = new EventEmitter<string>();
  @Output() readonly authorizeRequested = new EventEmitter<null>();
  @Output() readonly useAsDefaultRequested = new EventEmitter<string>();

  @ViewChildren(DomainInfoCardComponent) domainInfoCards: DomainInfoCardComponent[];

  constructor(private readonly snackbar: SnackbarService) {}

  async handleAuthorizeClick(): Promise<void> {
    this.authorizeRequested.emit(null);
  }

  scrollToDomain(domain: string): void {
    this.domainInfoCards
      .filter((c) => c.domain?.sendFromDomain === domain)
      .forEach((card) => {
        card.expanded = true;
      });
    window.setTimeout(
      () => document.getElementById(`domain_card_${domain}`).scrollIntoView({ behavior: 'smooth' }),
      500,
    );
  }
}
