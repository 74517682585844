import { Injectable } from '@angular/core';
import { DataProviderConfig, KeyedDataProvider } from '@vendasta/data-providers';
import { ListSendGridSubUserReputationsResponse, SendgridSubUsersService } from '@vendasta/email';
import { Observable, of, throwError } from 'rxjs';

type Response = ListSendGridSubUserReputationsResponse;
export interface Reputation {
  username: string;
  reputationPercent: number;
  color: string;
}

@Injectable({
  providedIn: 'root',
})
export class SendgridReputationsSource extends KeyedDataProvider<string, Response, Reputation[]> {
  constructor(svc: SendgridSubUsersService) {
    const cfg: DataProviderConfig<string, Response, Reputation[]> = {
      externalSource: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fetchFn: (i: string) => svc.listSubUserReputations(),
        uploadFn: () => throwError('Upload not supported'),
      },
      convert: {
        apiDataToAppData: responseToUIObject,
        inputsToStringKey: (i) => i,
      },
    };
    super(cfg);
  }
}

function computeColor(reputationPercent: number): string {
  if (reputationPercent < 95) {
    return 'warn';
  }
  return 'primary';
}

function responseToUIObject(r: Response): Observable<Reputation[]> {
  return of(
    r.reputations.map((v) => ({
      reputationPercent: v.reputationPercent,
      username: v.username,
      color: computeColor(v.reputationPercent),
    })),
  );
}
