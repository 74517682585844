import { Injectable } from '@angular/core';
import { DefaultDomainService } from '../../default-domain.service';
import { GetSenderInfoResponseInterface, SenderType } from '@vendasta/email';
import { SelectedDomain } from './selected-domain';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SelectedDomainFactoryService {
  constructor(private readonly defaultDomainService: DefaultDomainService) {}

  public fromSenderInfo(
    senderType: SenderType,
    senderInfo: GetSenderInfoResponseInterface,
    useOwnDomain: boolean,
  ): Observable<SelectedDomain> {
    return this.computeSelectedDomain(senderType, senderInfo, useOwnDomain).pipe(
      switchMap((domainToUse) => {
        return this.defaultDomainService.isDefaultDomain$(domainToUse, senderType).pipe(
          map((isDefault) => {
            return new SelectedDomain(domainToUse, senderInfo, isDefault);
          }),
        );
      }),
    );
  }

  public default(senderType: SenderType): Observable<SelectedDomain> {
    return this.defaultDomainService
      .getDefaultDomain$(senderType)
      .pipe(map((defaultDomain) => new SelectedDomain(defaultDomain, {}, true)));
  }

  private computeSelectedDomain(
    senderType: SenderType,
    senderInfo: GetSenderInfoResponseInterface,
    useOwnDomain: boolean,
  ): Observable<string> {
    return combineLatest([
      this.defaultDomainService.getDefaultDomain$(senderType),
      this.defaultDomainService.isDefaultDomain$(senderInfo.domain, senderType),
    ]).pipe(
      take(1),
      map(([defaultDomain, isOwnDomainDefault]) => {
        if (!useOwnDomain) {
          return defaultDomain;
        }
        if (isOwnDomainDefault) {
          return undefined;
        }
        return senderInfo.domain;
      }),
    );
  }
}
