import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { AdminService } from '@vendasta/email';
import { GalaxyAlertModule } from '@vendasta/galaxy/alert';
import { GalaxyEmptyStateModule } from '@vendasta/galaxy/empty-state';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { GalaxyInputModule } from '@vendasta/galaxy/input';
import { GalaxyPageModule } from '@vendasta/galaxy/page';
import { GalaxyPopoverModule } from '@vendasta/galaxy/popover';
import { AsyncUiModule, VaMaterialTableModule } from '@vendasta/uikit';
import { ResubscribeModule } from '../send-grid-index/resubscribe/resubscribe.module';
import { SendGridIndexComponent } from '../send-grid-index/send-grid-index.component';
import { UnbounceModule } from '../send-grid-index/unbounce/unbounce.module';
import { SendGridReputationComponent } from '../send-grid-reputation/send-grid-reputation.component';
import { SendgridAdminsTableComponent } from './sendgrid-admins/sendgrid-admins-table/sendgrid-admins-table.component';
import { SendgridAdminsComponent } from './sendgrid-admins/sendgrid-admins.component';

@NgModule({
  declarations: [
    SendgridAdminsComponent,
    SendgridAdminsTableComponent,
    SendGridReputationComponent,
    SendGridIndexComponent,
  ],
  imports: [
    CommonModule,
    GalaxyPageModule,
    MatTableModule,
    VaMaterialTableModule,
    GalaxyAlertModule,
    AsyncUiModule,
    GalaxyEmptyStateModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatProgressBarModule,
    GalaxyInputModule,
    GalaxyFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    GalaxyPopoverModule,
    MatProgressSpinnerModule,
    ResubscribeModule,
    UnbounceModule,
    MatDividerModule,
  ],
  providers: [AdminService],
})
export class SendgridAdminsModule {}
