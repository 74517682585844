<div mat-dialog-content autoFocus="false">
  <div class="wrapper">
    <!-- Email Preview Pane -->
    <div class="email-preview-pane">
      <!-- Preview Controls -->

      <div class="email-preview-controls">
        <div class="layouts">
          <div class="device-previews">
            <button (click)="changePreviewWidth('100%')" mat-button>
              <mat-icon>computer</mat-icon>
              Desktop
            </button>
            <button (click)="changePreviewWidth('600px')" mat-button>
              <mat-icon>tablet_mac</mat-icon>
              Tablet
            </button>
            <button (click)="changePreviewWidth('320px')" mat-button>
              <mat-icon>phone_iphone</mat-icon>
              Mobile
            </button>
          </div>
          <div class="code-previews">
            <button (click)="toggleSourceCode()" mat-button>
              <mat-icon>code</mat-icon>
              Html
            </button>
          </div>
        </div>

        <div class="share-button">
          <button mat-raised-button color="primary" [appCopyToClipboard]="shareableURL">
            <mat-icon>share</mat-icon>
            <span>Share</span>
          </button>
        </div>
      </div>

      <!-- Email Preview and Sourcecode -->
      <div class="email-preview">
        <iframe id="email-preview-iframe" sandbox [srcdoc]="htmlContent | iTrustThisHtml"></iframe>
        <div>
          <textarea id="source-code" readonly>{{ htmlContent }}</textarea>
        </div>
      </div>
    </div>
    <!-- Email Information Pane -->
    <div class="email-info-pane">
      <button mat-icon-button mat-dialog-close class="close-modal">
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
      <div class="email-info-container email-info">
        <div class="subject">
          <span class="info-label">Subject</span>
          {{ email.subject }}
        </div>
        <div class="email-to-from" *ngFor="let to of email.to">
          <span class="info-label">To</span>
          <span class="email-name">
            {{ to.name }}
          </span>
          <span class="email-email">({{ to.emailAddress }})</span>
        </div>
        <div class="email-info-data email-info-name">
          <span class="info-label">From</span>
          <span class="email-name">
            {{ email.from.name }}
          </span>
          <span class="email-email">({{ email.from.emailAddress }})</span>
        </div>
        <div class="email-info-data email-info-name" *ngIf="!!email.replyTo">
          <span class="info-label">Reply-To</span>
          <span class="email-name">
            {{ email.replyTo.name }}
          </span>
          <span class="email-email">({{ email.replyTo.emailAddress }})</span>
        </div>
      </div>

      <div class="dev-buttons">
        <ng-container *ngIf="!!vStoreLocation">
          <a [href]="vStoreLocation" target="_blank">
            <button mat-raised-button>vStore Message</button>
          </a>
        </ng-container>
        <ng-container *ngIf="!!codeLocation">
          <a [href]="codeLocation" target="_blank">
            <button mat-raised-button>GitHub Source</button>
          </a>
        </ng-container>
      </div>

      <!-- Secondary Information Tabs -->
      <!-- the @.disabled stops the tab switching animation -->
      <mat-tab-group [@.disabled]="true" [selectedIndex]="selectedTab" (selectedTabChange)="activeTab($event)">
        <!-- Events Tab -->
        <mat-tab label="Events">
          <ng-container *ngIf="events$ | async as events; else noEvents">
            <ul class="event-list">
              <li *ngFor="let e of events" [ngSwitch]="eventTypeToString(e.eventType)">
                <div class="event-row">
                  <!-- Change Icon based on what type of event it is -->
                  <mat-icon *ngSwitchCase="'OPENED'">drafts</mat-icon>
                  <mat-icon *ngSwitchCase="'DELIVERED'">mail</mat-icon>
                  <mat-icon *ngSwitchCase="'PROCESSED'">send</mat-icon>
                  <mat-icon *ngSwitchCase="'BOUNCED'">trending_down</mat-icon>
                  <mat-icon *ngSwitchCase="'DEFERRED'">replay</mat-icon>
                  <mat-icon *ngSwitchCase="'DROPPED'">remove_circle_outline</mat-icon>
                  <mat-icon *ngSwitchDefault>crop_square</mat-icon>
                  <div class="event-details">
                    <h4 class="key">
                      {{ eventTypeToString(e.eventType) | titlecase }}
                    </h4>
                    <p class="line" *ngIf="!!e.urlClicked">
                      {{ e.urlClicked }}
                    </p>
                    <p class="line">{{ e.eventTime | date: 'medium' }}</p>
                    <h4 class="value" *ngIf="!!e.reason">
                      {{ e.reason }}
                    </h4>
                  </div>
                </div>
              </li>
            </ul>
          </ng-container>
          <ng-template #noEvents>
            <mat-list-item>No events found</mat-list-item>
          </ng-template>
        </mat-tab>
        <!-- Attributes Tab -->
        <mat-tab label="{{ 'EMAIL_REVIEW.MORE_INFO' | translate }}">
          <ul class="info-list">
            <li *ngFor="let inf of info">
              <div class="key">{{ inf.key }}</div>
              <div class="info-value">
                <div>{{ inf.value }}</div>
                <mat-icon
                  matTooltip="{{ 'COPY_TO_CLIPBOARD.COPY' | translate }}"
                  [appCopyToClipboard]="inf.value"
                  class="copy-icon"
                >
                  file_copy
                </mat-icon>
              </div>
            </li>
          </ul>
        </mat-tab>
        <mat-tab *ngIf="showSpamScore">
          <ng-template mat-tab-label>
            <div class="spam-tab">
              <div class="spam-text">{{ 'EMAIL_REVIEW.SPAM.SPAM' | translate }}</div>
              <div *ngIf="(this.is_loading$ | async) === false; else loading">
                <div *ngIf="!spamScore.isSpam; else spamFailed">
                  <div class="spam-passed">
                    <mat-icon class="spam-score-approved">check_circle_outline</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ul class="info-list">
            <div>
              <div class="key">{{ 'EMAIL_REVIEW.SPAM.SCORE' | translate }}</div>
              <div class="info-value">
                <div *ngIf="(this.is_loading$ | async) === false; else loading">{{ spamScore?.score }}</div>
                <mat-icon
                  matTooltip="{{ 'COPY_TO_CLIPBOARD.COPY' | translate }}"
                  [appCopyToClipboard]="spamScore.score.toString()"
                  class="copy-icon"
                >
                  file_copy
                </mat-icon>
              </div>
            </div>
            <div>
              <div class="key">{{ 'EMAIL_REVIEW.SPAM.IS_SPAM' | translate }}</div>
              <div class="info-value">
                <div *ngIf="(this.is_loading$ | async) === false; else loading">{{ spamScore?.isSpam }}</div>
              </div>
            </div>
          </ul>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loading">
    <mat-spinner diameter="25" />
  </div>
</ng-template>

<ng-template #spamFailed>
  <div class="spam-failed">
    <mat-icon class="spam-score-approved">cancel</mat-icon>
  </div>
</ng-template>
