import { Component, OnInit } from '@angular/core';
import { GLOBAL_INPUT } from '@vendasta/data-providers';
import { map } from 'rxjs/operators';
import { SendgridAdminsSource } from '../../../../data-providers';
import { buildReloadVis$, nicerLoading } from '../../ui-utils/async';

@Component({
  templateUrl: './sendgrid-admins.component.html',
  styleUrls: ['./sendgrid-admins.component.scss'],
})
export class SendgridAdminsComponent implements OnInit {
  readonly loading$ = nicerLoading(this.data.state.isLoading$(GLOBAL_INPUT));
  readonly failed$ = this.data.state.isSuccess$(GLOBAL_INPUT).pipe(map((v) => !v));
  readonly data$ = this.data.getCachedOrFetch$(GLOBAL_INPUT);
  readonly reloadVis$ = buildReloadVis$(GLOBAL_INPUT, this.data);

  constructor(private readonly data: SendgridAdminsSource) {}

  ngOnInit(): void {
    this.data.requestRefresh(GLOBAL_INPUT);
  }

  handleTryAgain(): void {
    this.data.requestRefresh(GLOBAL_INPUT);
  }
}
