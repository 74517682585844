<va-mat-table>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">
        <div>{{ element.name }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element">
        <div>{{ element.email }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let element">
        <div>{{ element.role }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="slack">
      <th mat-header-cell *matHeaderCellDef>Slack</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <a href="{{ element.slackUrl }}" target="_blank">
            {{ element.slackName }}
          </a>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</va-mat-table>
