<glxy-page>
  <glxy-page-toolbar>
    <glxy-page-title>Users</glxy-page-title>
  </glxy-page-toolbar>
  <glxy-page-wrapper widthPreset="narrow">
    <glxy-alert type="tip">Here you can find tools for working with customers of the email microservice</glxy-alert>
    <app-recipient-status />
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title class="mat-mdc-card-title">Email Marketing Preferences (Read Only)</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="filters">
          <form [formGroup]="searchForm">
            <EXP__glxy-wrap>
              <mat-form-field>
                <mat-label>What kind of sender?</mat-label>
                <mat-select formControlName="senderType">
                  <mat-option [value]="partnerSenderType">Partner</mat-option>
                </mat-select>
              </mat-form-field>
            </EXP__glxy-wrap>

            <EXP__glxy-wrap>
              <mat-form-field>
                <mat-label>Partner ID</mat-label>
                <!-- TODO: Dynamic label depending on sender type -->
                <input type="text" matInput formControlName="senderId" />
              </mat-form-field>
            </EXP__glxy-wrap>

            <EXP__glxy-wrap>
              <mat-form-field>
                <mat-label>User ID</mat-label>
                <input type="text" matInput formControlName="userId" />
              </mat-form-field>
            </EXP__glxy-wrap>
          </form>
        </div>
        <div class="preferences">
          <ng-container *ngIf="(showEmptyState$ | async) || (noPreferences$ | async); else preferences">
            <glxy-empty-state>
              <glxy-empty-state-title>No Preferences Found</glxy-empty-state-title>
              <p>Enter a valid set of IDs</p>
            </glxy-empty-state>
          </ng-container>

          <ng-template #preferences>
            <uikit-async-ui
              [loading]="loading$ | async"
              [error]="failed$ | async"
              [data]="data$ | async"
              loadingMargin="auto"
              errorMargin="auto"
              [reloadVisibility]="loadStyle$ | async"
            >
              <ng-container *successData="let data">
                <email-preferences [preferenceIDs]="data.preferenceIds" [categories]="data.categories" />
              </ng-container>
            </uikit-async-ui>
          </ng-template>
        </div>
      </mat-card-content>
    </mat-card>
  </glxy-page-wrapper>
</glxy-page>
