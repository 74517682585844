import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectorRef, Component, ElementRef, Input, Pipe, PipeTransform, Self } from '@angular/core';
import { VerifyPendingEmailDomainMismatchInterface } from '@vendasta/email';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

export interface RecordsRow {
  recordType: 'CNAME' | 'TXT' | 'A_RECORD';
  recordHost: string;
  recordValue: string;
  recordValueMatchPrefix?: string;
  recordValueMismatchPortion?: string;
  recordValueMatchSuffix?: string;
}

@Pipe({
  name: 'toTableRowClass',
})
export class ToTableRowClassPipe implements PipeTransform {
  transform(value: any): any {
    const row: RecordsRow = value;
    if (row.recordValueMatchPrefix?.length > 0 && row.recordValueMismatchPortion?.length === 0) {
      return 'match';
    }
  }
}

function splitOnce(s: string, on: string): string[] {
  const [first, ...rest] = s.split(on);
  return [first, rest.length > 0 ? rest.join(on) : null];
}

@Component({
  selector: 'email-domain-records-table',
  templateUrl: './domain-records-table.component.html',
  styleUrls: ['./domain-records-table.component.scss'],
})
export class DomainRecordsTableComponent {
  private readonly mobileMaxWidth: number = 768;
  private readonly isContainerLarge$$ = new ReplaySubject<boolean>(1);
  readonly isContainerLarge$ = this.isContainerLarge$$.asObservable().pipe(
    distinctUntilChanged(),
    tap(() => this.cd.detectChanges()),
  );

  @Input() expectedRecords: RecordsRow[] = [];

  @Input() set mismatchedRecords(v: VerifyPendingEmailDomainMismatchInterface[]) {
    if (v == undefined) {
      return;
    }

    this.expectedRecords = this.expectedRecords.map((er) => {
      const mismatch = v.find((vr) => vr.url === er.recordHost);
      if (!mismatch) {
        return {
          ...er,
          recordValueMatchPrefix: er.recordValue,
        };
      }

      return {
        ...er,
        recordValueMatchPrefix: mismatch?.matchedPrefix,
        recordValueMismatchPortion: this.findMismatchedPortion(mismatch),
        recordValueMatchSuffix: mismatch?.matchedSuffix,
      };
    });
    this.cd.markForCheck();
  }

  private findMismatchedPortion(mismatch: VerifyPendingEmailDomainMismatchInterface) {
    const withPrefixRemoved = mismatch?.matchedPrefix
      ? splitOnce(mismatch?.actualValue, mismatch?.matchedPrefix)[1]
      : '';
    return mismatch?.matchedSuffix ? splitOnce(withPrefixRemoved, mismatch?.matchedSuffix)[0] : withPrefixRemoved;
  }

  @Input() showMismatches: boolean;

  constructor(
    @Self() private readonly element: ElementRef,
    private readonly cd: ChangeDetectorRef,
    private readonly clipboard: Clipboard,
    private readonly snackbar: SnackbarService,
  ) {
    const ro = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target !== this.element.nativeElement) {
          continue;
        }
        const cr = entry.contentRect;
        const isLarge = cr.width >= this.mobileMaxWidth;
        this.isContainerLarge$$.next(isLarge);
      }
    });

    // Element for which to observe height and width
    ro.observe(this.element.nativeElement);
  }

  copyValue(recordValue: string): void {
    this.clipboard.copy(recordValue);
    this.snackbar.openSuccessSnack('DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.COPY_SUCCESS');
  }
}
