import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomainInfoCardComponent } from './domain-info-card.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DomainStatusIconModule } from '../domain-status-icon/domain-status-icon.module';
import { GalaxyLoadingSpinnerModule } from '@vendasta/galaxy/loading-spinner';
import { GalaxyTooltipModule } from '@vendasta/galaxy/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { GalaxyPipesModule } from '@vendasta/galaxy/pipes';
import { GalaxyAlertModule } from '@vendasta/galaxy/alert';
import { DomainRecordsTableModule } from '../domain-records-table/domain-records-table.module';
import { MatButtonModule } from '@angular/material/button';
import { GalaxyButtonLoadingIndicatorModule } from '@vendasta/galaxy/button-loading-indicator';
import { EmailUII18nModule } from '../../../src/lib/i18n/email-ui-i18n.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [DomainInfoCardComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    DomainStatusIconModule,
    DomainRecordsTableModule,
    GalaxyLoadingSpinnerModule,
    GalaxyTooltipModule,
    MatIconModule,
    GalaxyPipesModule,
    GalaxyAlertModule,
    MatButtonModule,
    GalaxyButtonLoadingIndicatorModule,
    EmailUII18nModule,
    TranslateModule,
    MatDialogModule,
  ],
  exports: [DomainInfoCardComponent],
})
export class DomainInfoCardModule {}
