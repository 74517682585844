import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomainsComponent } from './domains.component';
import { GalaxyEmptyStateModule } from '@vendasta/galaxy/empty-state';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DomainInfoCardModule } from '../domain-info-card/domain-info-card.module';
import { NewDomainDialogModule } from '../new-domain-dialog/new-domain-dialog.module';
import { CanAddDomainPipe } from './can-add-domain.pipe';
import { EmailUII18nModule } from '../../../src/lib/i18n/email-ui-i18n.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DomainsComponent, CanAddDomainPipe],
  imports: [
    CommonModule,
    GalaxyEmptyStateModule,
    MatIconModule,
    MatButtonModule,
    DomainInfoCardModule,
    NewDomainDialogModule,
    EmailUII18nModule,
    TranslateModule,
  ],
  exports: [DomainsComponent],
})
export class DomainsModule {}
