<glxy-page>
  <glxy-page-toolbar>
    <glxy-page-title>SendGrid</glxy-page-title>
  </glxy-page-toolbar>
  <glxy-page-wrapper widthPreset="narrow">
    <glxy-alert type="tip">Here you can find tools for working with our SendGrid integration(s)</glxy-alert>
    <mat-card appearance="outlined" class="nav-container">
      <a href="https://github.com/vendasta/email" target="_blank">
        <button class="nav-button" mat-raised-button>
          <mat-icon>code</mat-icon>
          <div>Email Source Code</div>
        </button>
      </a>
      <a href="/sendgrid/admins">
        <button class="nav-button" mat-raised-button>
          <mat-icon>people</mat-icon>
          <div>SendGrid Admins</div>
        </button>
      </a>
      <a href="/sendgrid/reputation">
        <button class="nav-button" mat-raised-button>
          <mat-icon>stars</mat-icon>
          <div>Sub-User Reputation</div>
        </button>
      </a>
    </mat-card>

    <mat-card appearance="outlined" class="search-form">
      <app-resubscribe />
    </mat-card>
    <br />
    <mat-card appearance="outlined" class="search-form">
      <app-unbounce />
    </mat-card>
  </glxy-page-wrapper>
</glxy-page>
