import { Injectable } from '@angular/core';
import { DomainService, SenderType } from '@vendasta/email';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// Remove these once the backend returns the correct default domain [MAEL-1319](https://vendasta.jira.com/browse/MAEL-1319)
const LEGACY_DEFAULT_DOMAIN = 'smblogin.com';
const DEFAULT_DOMAIN = 'businessmail.io';

export function repairDefaultDomain(domain: string): string {
  if (domain.toLowerCase() === DEFAULT_DOMAIN) {
    return LEGACY_DEFAULT_DOMAIN;
  }
  return domain;
}

@Injectable({ providedIn: 'root' })
export class DefaultDomainService {
  defaultDomainCache: Map<SenderType, string> = new Map();

  constructor(private domainService: DomainService) {}

  getDefaultDomain$(senderType: SenderType): Observable<string> {
    if (this.defaultDomainCache.has(senderType)) {
      return of(this.defaultDomainCache.get(senderType));
    }

    return this.domainService.getDefaultDomain(senderType).pipe(
      tap((response) =>
        this.defaultDomainCache.set(
          senderType,
          response.domain === LEGACY_DEFAULT_DOMAIN ? DEFAULT_DOMAIN : response.domain,
        ),
      ),
      map((response) => (response.domain === LEGACY_DEFAULT_DOMAIN ? DEFAULT_DOMAIN : response.domain)),
    );
  }

  isDefaultDomain$(domain: string, senderType: SenderType): Observable<boolean> {
    if (domain === DEFAULT_DOMAIN || domain === LEGACY_DEFAULT_DOMAIN) {
      return of(true);
    } else {
      return this.getDefaultDomain$(senderType).pipe(map((defaultDomain) => domain === defaultDomain));
    }
  }
}
