<glxy-page>
  <glxy-page-toolbar>
    <glxy-page-title>Users</glxy-page-title>
  </glxy-page-toolbar>
  <glxy-page-wrapper widthPreset="narrow">
    <glxy-alert type="tip">Here you can find tools for working with senders from the email microservice</glxy-alert>
    <mat-card appearance="outlined">
      <mat-tab-group>
        <mat-tab label="Domain Records">
          <ng-template matTabContent>
            <app-domain-records [selection]="urlSelection" />
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </glxy-page-wrapper>
</glxy-page>
