<ng-container *ngIf="(searchResult$$ | async) === null; else resultComponent">
  <mat-card-header>
    <mat-card-title class="search-title">Resubscribe email recipients</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <EXP__glxy-wrap>
      <mat-form-field>
        <mat-label>What kind of sender?</mat-label>
        <mat-select id="sender-type" placeholder="Sender Type" [(value)]="senderType">
          <mat-option value="business">Business</mat-option>
          <mat-option value="partner">Partner</mat-option>
          <mat-option value="product">Product</mat-option>
        </mat-select>
      </mat-form-field>
    </EXP__glxy-wrap>
    <ng-container *ngIf="senderType !== ''">
      <form [formGroup]="searchForm">
        <glxy-input placeholder="Recipient Email" formControlName="email" />
        <glxy-input
          *ngIf="senderType === 'partner'"
          placeholder="Partner ID"
          formControlName="partnerId"
          trailingIcon="help_outline"
          [iconClickable]="true"
          (iconClicked)="openHelpNote()"
          [glxyPopover]="helpPopover"
          required='senderType == "partner"'
        />
        <glxy-popover
          #helpPopover
          [isOpen]="isHelpPopoverOpen"
          [positions]="[pos]"
          (backdropClick)="closeHelpNote()"
          [hasArrow]="true"
        >
          If the recipient you are searching for is not partner specific, leave this field blank
          <glxy-popover-actions>
            <button mat-stroked-button color="primary" (click)="closeHelpNote()">Close</button>
          </glxy-popover-actions>
        </glxy-popover>
        <button mat-flat-button color="primary" class="search-button" (click)="searchForUnsubscribed()">
          <ng-container *ngIf="(loading$$ | async) === false; else loadingSpinner">Search</ng-container>
        </button>
      </form>
    </ng-container>
  </mat-card-content>
</ng-container>

<ng-template #resultComponent>
  <div class="result-container" *ngIf="searchResult$$ | async as result">
    <glxy-alert type="warning">
      Re-subscribing a recipient requires their consent.
      <br />
      Be absolutely sure you have this before continuing!
    </glxy-alert>
    <div class="result-details">
      <div>Recipient Email: {{ result.email }}</div>
      <div *ngIf="result.partnerID !== ''">Partner ID: {{ result.partnerID }}</div>
    </div>

    <form [formGroup]="searchForm">
      <mat-checkbox formControlName="consent">I have the recipient's consent</mat-checkbox>
    </form>
    <div class="actions">
      <button mat-flat-button color="warn" (click)="goBack()">Go back</button>
      <button
        mat-flat-button
        color="primary"
        (click)="resubscribeRecipient(result)"
        [disabled]="!searchForm.get('consent').value"
      >
        <ng-container *ngIf="(loading$$ | async) === false; else loadingSpinner">Resubscribe</ng-container>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <mat-spinner class="loading" [diameter]="30" />
</ng-template>
