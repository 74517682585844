import { NgModule } from '@angular/core';
import { DomainsDialogComponent } from './domains-dialog.component';
import { GalaxyEmptyStateModule } from '@vendasta/galaxy/empty-state';
import { MatIconModule } from '@angular/material/icon';
import { GalaxyLoadingSpinnerModule } from '@vendasta/galaxy/loading-spinner';
import { GalaxyPipesModule } from '@vendasta/galaxy/pipes';
import { CommonModule } from '@angular/common';
import { DomainStatusAndNameComponent } from './domain-status-and-name/domain-status-and-name.component';
import { DomainStatusIconModule } from '../domain-status-icon/domain-status-icon.module';
import { MatButtonModule } from '@angular/material/button';
import { DomainInfoDialogModule } from '../domain-info-dialog/domain-info-dialog.module';
import { EmailUII18nModule } from '../../../src/lib/i18n/email-ui-i18n.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';

@NgModule({
  declarations: [DomainStatusAndNameComponent, DomainsDialogComponent],
  imports: [
    CommonModule,
    GalaxyPipesModule,
    GalaxyEmptyStateModule,
    GalaxyLoadingSpinnerModule,
    MatIconModule,
    DomainStatusIconModule,
    DomainInfoDialogModule,
    EmailUII18nModule,
    TranslateModule,
    MatDialogModule,
    MatListModule,
    MatButtonModule,
  ],
})
export class DomainsDialogModule {}
