import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SenderType } from '@vendasta/email';
import { ComponentCanDeactivate } from '../../../core/pending-changes.guard';
import { DomainRecordsComponent } from './domain-records/domain-records.component';

@Component({
  templateUrl: './email-senders.component.html',
  styleUrls: ['./email-senders.component.scss'],
})
export class EmailSendersComponent extends ComponentCanDeactivate implements OnInit {
  urlSelection: { senderType: SenderType; senderId: string };

  @ViewChild(DomainRecordsComponent) domainRecords: DomainRecordsComponent;

  constructor(private readonly activatedRoute: ActivatedRoute) {
    super();
  }

  canDeactivate(): boolean {
    if (this.domainRecords) {
      return this.domainRecords.canDeactivate();
    }
    return true;
  }

  ngOnInit(): void {
    const senderTypeFromURL = parseInt(this.activatedRoute.snapshot.params['senderType'], 10);
    const senderIdFromURL = this.activatedRoute.snapshot.params['senderId'];
    if (!!senderTypeFromURL && !!senderIdFromURL) {
      this.urlSelection = { senderType: senderTypeFromURL, senderId: senderIdFromURL };
    }
  }
}
