<ng-container *ngIf="!sender">
  <h1>{{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_CARD.INPUT_NOT_PROVIDED' | translate }}</h1>
</ng-container>
<ng-container *ngIf="senderInfo$ | glxyAsyncStatus | async as senderInfo">
  <ng-container [ngSwitch]="senderInfo.status">
    <ng-container *ngSwitchCase="'loaded'">
      <email-sender-info-form
        [sender]="sender"
        [senderInfo]="senderInfo.value"
        [domainOverride]="domainOverride"
        (domainChangeRequested)="setDomain($event)"
        (invalidDomainChangeRequested)="invalidDomainChangeRequested.emit($event)"
        (authorizeRequested)="handleAuthorizeRequested()"
      ></email-sender-info-form>
    </ng-container>
    <ng-container *ngSwitchCase="'loading'">
      <glxy-loading-spinner [fullWidth]="true"></glxy-loading-spinner>
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
      <glxy-empty-state>
        <p>{{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_CARD.LOADING_ERROR' | translate }}</p>
        <glxy-empty-state-actions>
          <button mat-raised-button color="primary" (click)="reInitializeObservables()">
            {{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_CARD.TRY_AGAIN' | translate }}
          </button>
        </glxy-empty-state-actions>
      </glxy-empty-state>
    </ng-container>
  </ng-container>
</ng-container>
