<form [formGroup]="formGroup">
  <ng-container *ngFor="let p of prefFields?.controls; let i = index">
    <EXP__glxy-wrap class="preference">
      <mat-checkbox [checked]="p.value" [disabled]="!allowEditing" (click)="toggleSelection(i)">
        <ng-container *ngIf="data[i]?.categoryName.length > 140; else short">
          <span [glxyTooltip]="data[i]?.categoryName">
            {{ data[i]?.categoryName | truncate: 140 }}
          </span>
        </ng-container>

        <ng-template #short>
          {{ data[i]?.categoryName }}
        </ng-template>
      </mat-checkbox>
    </EXP__glxy-wrap>
  </ng-container>
</form>
