<h2 mat-dialog-title>{{ 'DOMAIN_AUTHORIZATION.DOMAINS_DIALOG.HEADER' | translate }}</h2>
<mat-dialog-content>
  <ng-container *ngIf="allDomains$ | glxyAsyncStatus | async as data" [ngSwitch]="data.status">
    <!-- Begin state handling -->
    <ng-container *ngSwitchCase="'loaded'">
      <mat-selection-list [multiple]="false" [hideSingleSelectionIndicator]="true">
        <ng-container *ngFor="let data of data.value">
          <mat-list-option>
            <email-domain-status-and-name
              [data]="{ domain: data.domain, sender: data.sender }"
              (clicked)="self.close()"
              (domainChangeRequested)="handleDomainChangeRequested($event, true)"
              (invalidDomainChangeRequested)="handleDomainChangeRequested($event, false)"
            ></email-domain-status-and-name>
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </ng-container>

    <ng-container *ngSwitchCase="'loading'">
      <glxy-loading-spinner></glxy-loading-spinner>
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
      <ng-container *ngTemplateOutlet="error"></ng-container>
    </ng-container>
    <!-- End state handling -->

    <ng-template #error>
      <glxy-empty-state [size]="'small'">
        <glxy-empty-state-hero>
          <mat-icon color="warn">error</mat-icon>
        </glxy-empty-state-hero>
        <glxy-empty-state-title>{{ 'DOMAIN_AUTHORIZATION.DOMAINS_DIALOG.ERROR' | translate }}</glxy-empty-state-title>
        <p>{{ data.error }}</p>
      </glxy-empty-state>
    </ng-template>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <!-- TODO: Support more than two domains [EM-1678] -->
  <div class="dialog-secondary-actions" *ngIf="(allDomains$ | async)?.length < 2">
    <button mat-button color="primary" (click)="handleNewDomainRequested()">
      {{ 'DOMAIN_AUTHORIZATION.NEW_DOMAIN_DIALOG.ADD_DOMAIN' | translate }}
    </button>
  </div>
  <button mat-raised-button matDialogClose>
    {{ 'DOMAIN_AUTHORIZATION.NEW_DOMAIN_DIALOG.CANCEL' | translate }}
  </button>
</mat-dialog-actions>
