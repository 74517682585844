import { Injectable } from '@angular/core';
import { DataProviderConfig, KeyedDataProvider } from '@vendasta/data-providers';
import {
  GetCategoryPreferencesResponse as Response,
  GetCategoryPreferencesResponseInterface as ResponseInterface,
  PreferencesService,
  SenderType,
} from '@vendasta/email';
import { of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

export interface PreferenceInputs {
  senderType: SenderType;
  senderId: string;
  userId: string;
}

@Injectable()
export class EmailPreferencesSource extends KeyedDataProvider<PreferenceInputs, Response, ResponseInterface> {
  constructor(svc: PreferencesService) {
    const cfg: DataProviderConfig<PreferenceInputs, Response, Response> = {
      externalSource: {
        fetchFn: (i: PreferenceInputs) => svc.getCategoryPreferences(i.senderType, i.senderId, i.userId),
        uploadFn: (i: PreferenceInputs, preference: ResponseInterface) =>
          svc
            .saveCategoryPreferencesForUser(i.senderType, i.senderId, i.userId, preference.optedInEmailCategoryIds)
            .pipe(mapTo(null)),
      },
      convert: {
        apiDataToAppData: of,
        inputsToStringKey: (i) => [i.senderType.toString(), i.senderId, i.userId].join(':'),
      },
    };
    super(cfg);
  }
}
