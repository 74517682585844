import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewDomainDialogComponent } from './new-domain-dialog.component';
import { GalaxyWrapModule } from '@vendasta/galaxy/galaxy-wrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { GalaxyButtonLoadingIndicatorModule } from '@vendasta/galaxy/button-loading-indicator';
import { GalaxyPipesModule } from '@vendasta/galaxy/pipes';
import { EmailUII18nModule } from '../../../src/lib/i18n/email-ui-i18n.module';
import { MatDialogModule } from '@angular/material/dialog';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';

@NgModule({
  declarations: [NewDomainDialogComponent],
  imports: [
    CommonModule,
    GalaxyWrapModule,
    FormsModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    GalaxyButtonLoadingIndicatorModule,
    GalaxyPipesModule,
    EmailUII18nModule,
    TranslateModule,
    MatDialogModule,
    GalaxyFormFieldModule,
  ],
  exports: [NewDomainDialogComponent],
})
export class NewDomainDialogModule {}
