<form [formGroup]="form">
  <div class="email">
    <glxy-form-field [bottomSpacing]="false" [suffixText]="'@' + (domain$ | async)">
      <glxy-label>{{ 'COMMON.EMAIL_ADDRESS' | translate }}</glxy-label>
      <input type="text" matInput [formControlName]="formControlNameString" autocomplete="off" />
      <glxy-error *ngIf="form.get(formControlNameString).invalid && form.touched">
        {{ 'EMAIL_USERNAME_INPUT.EMAIL_REQUIRED' | translate }}
      </glxy-error>
    </glxy-form-field>
    <mat-icon
      *ngIf="(isDefaultDomain$ | async) === false"
      [glxyTooltip]="'EMAIL_USERNAME_INPUT.USE_DIFFERENT_DOMAIN' | translate"
      (click)="changeDomain()"
    >
      edit
    </mat-icon>
  </div>
</form>
