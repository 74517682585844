import { Component, Inject, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ContentContentType,
  EmailEvent,
  EmailEventService,
  EmailMessage,
  EventType,
  PagedRequestOptions,
} from '@vendasta/email';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { take } from 'rxjs/operators';
import { devGoMappings, devPyMappings } from './dev-mappings';
import { EnvironmentService } from '@galaxy/core';
import { ScoringService } from '@vendasta/spambam';

interface SpamScore {
  isSpam: boolean;
  score: number;
  baseScore: number;
}
@Component({
  selector: 'app-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss'],
})
export class EmailPreviewDialogComponent implements OnInit {
  email: EmailMessage;
  info: { key: string; value: string }[] = [];
  events$: Observable<EmailEvent[]>;
  private _events$$: BehaviorSubject<EmailEvent[]> = new BehaviorSubject<EmailEvent[]>([]);

  // Initial tab to open
  selectedTab: any = 1;
  shareableURL: string;
  codeLocation: string | undefined;
  vStoreLocation: string | undefined;
  spamScore: SpamScore = {
    isSpam: false,
    score: 0,
    baseScore: 0,
  };
  is_loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  is_loading$: Observable<boolean> = this.is_loading$$.asObservable();
  showSpamScore = false;

  get htmlContent(): string {
    let msg = this.email.content[0];
    const htmlContents = this.email.content.filter((c) => c.type === ContentContentType.TEXT_HTML);
    if (htmlContents.length > 0) {
      msg = htmlContents[0];
    }
    if (msg.sensitive) {
      return '<h3>🔐 The contents of this email are sensitive and have been encrypted</h3>';
    }
    return msg.value;
  }

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private _eventService: EmailEventService,
    public dialogRef: MatDialogRef<EmailPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _email: EmailMessage,
    private readonly environment: EnvironmentService,
    private readonly spamBamService: ScoringService,
    private route: ActivatedRoute,
  ) {
    this.email = _email;
    this.info.push({ key: 'EmailID', value: this.email.emailId });
    this.info.push(...(this.email.attributes || []));
    this.info.push({ key: 'Sub User', value: this.email.serviceProviderInfo?.subUserId });
    this.initializeSharableURL(_email.emailId);
    this.codeLocation = this.extractCodeLocation(_email);
    const env = environment.getEnvironmentString();
    this.vStoreLocation = `https://vstore-${env}.vendasta-internal.com/namespace/email/table/Message/entity/${_email.emailId}`;
  }

  private initializeSharableURL(emailId): void {
    const tree = this.router.createUrlTree([`email`, `message`, emailId]);
    this.location.go(tree.toString());

    this.shareableURL = `${window.location.protocol}//${window.location.host}/${tree.toString()}`;

    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        const mainTree = this.router.createUrlTree([`email`]);
        this.location.go(mainTree.toString());
      });
  }

  changePreviewWidth(newWidth): void {
    const preview = document.getElementById('email-preview-iframe');
    preview.style.width = newWidth;

    // hide sourceCode if visible
    const sourceCode = document.getElementById('source-code');
    if (sourceCode.style.display === 'block') {
      sourceCode.style.display = 'none';
    }
  }

  toggleSourceCode(): void {
    const sourceCode = document.getElementById('source-code');
    if (sourceCode.style.display === 'block') {
      sourceCode.style.display = 'none';
    } else {
      sourceCode.style.display = 'block';
    }
  }

  activeTab(currentTab?): void {
    const storedTab = sessionStorage.getItem('storedTab');

    if (currentTab) {
      // If a new tab is passed in, then update the saved
      // value in sessionStorage.
      sessionStorage.setItem('storedTab', currentTab.index);
    } else if (storedTab) {
      // If no new tab is passed in, the set the current tab to
      // what is saved in sessionStorage
      this.selectedTab = storedTab;
    }
  }

  ngOnInit(): void {
    const po = new PagedRequestOptions({ cursor: '', pageSize: 15 });
    this.activeTab();
    this.events$ = this._events$$.asObservable();
    this._eventService.listEvents(this.email.emailId, po).subscribe((res) => {
      this._events$$.next(res.events);
    });
    this.showSpamScore = this.route.snapshot.queryParamMap.get('withSpamScore') != undefined;
    if (this.email != undefined && this.showSpamScore) {
      this.getSpamScore();
    }
  }

  getSpamScore(): void {
    this.is_loading$$.next(true);
    this.spamBamService
      .getSpamScore(
        this.email.subject,
        this.email.content.map((c) => c.value).join('\n'),
        this.email.from.emailAddress,
        this.email.to[0].emailAddress,
        this.email.timestamp,
        this.email.emailId,
      )
      .subscribe((res) => {
        this.spamScore.isSpam = res?.isSpam != undefined;
        this.spamScore.score = res?.score;
        this.spamScore.baseScore = res?.baseScore;
        this.is_loading$$.next(false);
      });
  }

  eventTypeToString(e: EventType): string {
    return EventType[e];
  }

  private extractCodeLocation(email: EmailMessage) {
    if (!email.attributes) {
      return undefined;
    }
    const codeLocation = email.attributes.find((a) => a.key === 'sdk-source');
    if (!codeLocation) {
      return undefined;
    }
    const ghStart = devGoMappings[email.originUserId];
    if (codeLocation.value.startsWith('/workspace/')) {
      const locWithLineNo = codeLocation.value.split('/workspace/')[1];
      if (ghStart) {
        const filePath = locWithLineNo.split(':')[0];
        const lineNumber = locWithLineNo.split(':')[1];
        return `${ghStart}${filePath}#L${lineNumber}`;
      }
      return undefined;
    }

    for (const regex in devPyMappings) {
      const match = codeLocation.value.match(regex);
      if (match) {
        const restOfText = match[2];

        const repoUrl = devPyMappings[regex];
        const filePath = restOfText.split(':')[0];
        const lineNumber = restOfText.split(':')[1];

        return `${repoUrl}${filePath}#L${lineNumber}`;
      }
    }

    if (ghStart) {
      return ghStart;
    }
    return undefined;
  }
}
