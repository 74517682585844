import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  DomainService,
  GetSenderInfoResponse,
  GetSenderInfoResponseInterface,
  Sender,
  SenderDomain,
  SenderType,
} from '@vendasta/email';
import { SubscriptionList } from '@vendasta/rx-utils';
import { combineLatest, firstValueFrom, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { EmailValidators } from '../../../../email-form-validators';
import { DomainCacheService } from '../../domain-cache.service';
import { DomainsDialogComponent, Result as DialogResult } from '../../domains-dialog/domains-dialog.component';
import { SavedState, UNSAVED_DATA_HANDLER_TOKEN, UnsavedDataHandler } from '../dependencies';
import { SelectedDomain } from './selected-domain';
import { DefaultDomainService, repairDefaultDomain } from '../../../src/default-domain.service';
import { SelectedDomainFactoryService } from './selected-domain-factory.service';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { EmailApiService, GlobalParticipantType } from '@vendasta/conversation';
import { Clipboard } from '@angular/cdk/clipboard';
import { EmailForwardingInfoComponent } from './email-forwarding-info/email-forwarding-info.component';

export interface SaveData {
  domain: string;
  preferredDisplayName: string;
  preferredEmailUsername: string;
  senderName: string;
  replyTo: string;
}

@Component({
  selector: 'email-sender-info-form',
  templateUrl: './sender-info-form.component.html',
  styleUrls: ['./sender-info-form.component.scss'],
})
export class SenderInfoFormComponent implements OnInit, OnChanges {
  @Output() readonly domainChangeRequested = new EventEmitter<string>();
  @Output() readonly invalidDomainChangeRequested = new EventEmitter<string>();
  @Output() readonly authorizeRequested = new EventEmitter<void>();

  @Input() readonly sender: Sender;

  @Input() readonly senderInfo: GetSenderInfoResponse;

  /**
   * domainOverride sets the domain that is displayed in this component. It
   * is displayed INSTEAD OF the sender's actual domain. (e.g. prior to saving)
   */
  @Input() domainOverride: string;

  domains$: Observable<SenderDomain[]>;

  senderInfoForm: UntypedFormGroup = new UntypedFormGroup({
    senderName: new UntypedFormControl('', [Validators.required]),
    useOwn: new UntypedFormControl(''),
    senderEmailUsername: new UntypedFormControl('', [Validators.required, EmailValidators.emailUsername()]),
    replyTo: new UntypedFormControl('', [EmailValidators.fullEmailAddress()]),
  });

  forwardingAddress: Promise<string>;
  protected isCopied = false;
  private readonly emailApiService = inject(EmailApiService);

  selectedDomain$: Observable<SelectedDomain>;
  selectedDomainName$: Observable<string>;
  saveSenderInfoRequest$: Observable<any> = of(null);
  saveDomainRequest$: Observable<any> = of(null);

  useOwn: boolean;
  showAlertBanner = true;

  showForwardingAddress: boolean;

  private readonly subs = SubscriptionList.new();
  private notifier: Subscription | null = null;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Inject(UNSAVED_DATA_HANDLER_TOKEN) private readonly unsavedHandler: UnsavedDataHandler,
    private readonly dialog: MatDialog,
    private readonly domainService: DomainCacheService,
    private defaultDomainService: DefaultDomainService,
    private readonly selectedDomainFactory: SelectedDomainFactoryService,
    private readonly domains: DomainService,
    private readonly alerts: SnackbarService,
    private clipboard: Clipboard,
  ) {
    this.subs.add(this.senderInfoForm.get('useOwn').valueChanges, (value) => this.handleUseOwnChanged(value));
  }

  ngOnChanges(): void {
    this.updateFormValuesIfUnset(this.senderInfo);
    if (this.domainOverride) {
      this.senderInfoForm.get('useOwn').setValue(true);
      const senderInfo: GetSenderInfoResponseInterface = {
        domain: this.domainOverride,
        isPlatformAuthorized: true,
      };
      this.selectedDomain$ = this.selectedDomainFactory.fromSenderInfo(this.sender.type, senderInfo, true);
      this.selectedDomainName$ = this.selectedDomain$.pipe(map((d) => d.domain));
    }
  }

  ngOnInit(): void {
    this.showForwardingAddress = this.sender.type === SenderType.SENDER_TYPE_BUSINESS;
    this.domains$ = this.domainService.listAllDomains(this.sender.type, this.sender.id).pipe(map((r) => r.domains));
    this.forwardingAddress = this.getForwardingAddress();
  }

  private handleUseOwnChanged(useOwnDomain: boolean): void {
    this.useOwn = useOwnDomain;
    this.selectedDomain$ = this.selectedDomainFactory.fromSenderInfo(this.sender.type, this.senderInfo, useOwnDomain);
    this.selectedDomainName$ = this.selectedDomain$.pipe(map((d) => d.domain));
    this.cdr.detectChanges();
  }

  public handleAuthorizeDomain(): void {
    this.authorizeRequested.emit();
  }

  private updateFormValuesIfUnset(info: GetSenderInfoResponseInterface): void {
    this.notifier?.unsubscribe();
    this.senderInfoForm.get('useOwn').setValue(!info.isDefaultForSenderType);
    const senderName = this.senderInfoForm.get('senderName');
    if (!senderName.value) {
      senderName.setValue(info.preferredEmailDisplayName);
    }
    const senderEmailUsername = this.senderInfoForm.get('senderEmailUsername');
    if (!senderEmailUsername.value) {
      senderEmailUsername.setValue(info.preferredEmailUsername);
    }
    const replyTo = this.senderInfoForm.get('replyTo');
    if (!replyTo.value) {
      replyTo.setValue(info.preferredReplyToEmailAddress);
    }
    this.notifier = this.senderInfoForm.valueChanges.subscribe(() =>
      this.unsavedHandler.notifyStateChanged(SavedState.HAS_UNSAVED_CHANGES),
    );
  }

  handleSaveRequested($event: Observable<SaveData>, isDomainSave: boolean): void {
    const saveRequest = $event.pipe(
      switchMap((saveData) =>
        this.domains.changeForSender(
          this.sender.type,
          this.sender.id,
          repairDefaultDomain(saveData.domain),
          saveData.preferredEmailUsername,
          saveData.replyTo,
          saveData.senderName,
        ),
      ),
      tap(() => this.alerts.openSuccessSnack('DOMAIN_AUTHORIZATION.SENDER_INFO_CARD.UPDATE_SUCCESS')),
      catchError((err) => {
        this.alerts.openErrorSnack('DOMAIN_AUTHORIZATION.SENDER_INFO_CARD.UPDATE_FAILURE');
        return throwError(() => err);
      }),
      tap(() => this.unsavedHandler.notifyStateChanged(SavedState.SAFE_TO_NAVIGATE_AWAY)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    if (isDomainSave) {
      this.saveDomainRequest$ = saveRequest;
    } else {
      this.saveSenderInfoRequest$ = saveRequest;
    }
  }

  getSaveData(): Observable<SaveData> {
    // TODO: Prevent saving if form is not valid?
    const useOwnDomain = this.senderInfoForm.get('useOwn').value;
    return combineLatest([this.defaultDomainService.getDefaultDomain$(this.sender.type), this.selectedDomain$]).pipe(
      map(([defaultDomain, selectedDomain]) => {
        return {
          domain: useOwnDomain ? selectedDomain.domain : defaultDomain,
          preferredDisplayName: this.senderInfoForm.get('senderName').value,
          preferredEmailUsername: this.senderInfoForm.get('senderEmailUsername').value,
          senderName: this.senderInfoForm.get('senderName').value,
          replyTo: this.senderInfoForm.get('replyTo').value,
        } as SaveData;
      }),
    );
  }

  async openChooseDomainDialog(): Promise<void> {
    const dialog = this.dialog.open(DomainsDialogComponent, {
      data: this.sender,
      maxHeight: '600px',
    });
    const result: DialogResult = await firstValueFrom(dialog.afterClosed());
    switch (result.choiceType) {
      case 'new':
        this.authorizeRequested.emit();
        break;
      case 'valid':
        this.domainChangeRequested.emit(result.newDomain);
        break;
      case 'invalid':
        this.invalidDomainChangeRequested.emit(result.newDomain);
        break;
    }
  }

  closeAlert() {
    this.showAlertBanner = false;
  }

  async copyClicked() {
    this.isCopied = true;
    this.clipboard.copy(await this.forwardingAddress);
    setTimeout(() => {
      this.isCopied = false;
    }, 5000);
  }

  async getForwardingAddress(): Promise<string> {
    return await firstValueFrom(
      this.emailApiService
        .getOwnerEmail({
          subjectParticipant: {
            participantType: GlobalParticipantType.GLOBAL_PARTICIPANT_TYPE_ACCOUNT_GROUP,
            internalParticipantId: this.sender?.id ?? '',
          },
        })
        .pipe(
          map((emailRes) => {
            return emailRes.forwardingEmail ?? '';
          }),
          catchError(() => of('')),
        ),
    );
  }

  openLearnMoreModal(): void {
    this.dialog.open(EmailForwardingInfoComponent, { width: '600px' });
  }
}
