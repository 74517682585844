import { merge, Observable, of } from 'rxjs';
import { delay, filter, mapTo, startWith, switchMap } from 'rxjs/operators';
import { ReloadVisibility } from '@vendasta/uikit';
import { KeyedDataProvider } from '@vendasta/data-providers';

export function nicerLoading(loading$: Observable<boolean>): Observable<boolean> {
  return loading$.pipe(
    switchMap((isSending) => {
      if (isSending) {
        return of(true);
      }
      // More satisfying UX for very fast requests
      return of(false).pipe(delay(350));
    }),
  );
}

export function buildReloadVis$(id: string, data: KeyedDataProvider<string, any, any>): Observable<ReloadVisibility> {
  // None of this should be necessary. TODO: Fix async-ui so we can just use:
  // this.reloadVis$ = this.data$.pipe(mapTo('data-visible'));
  const showWhenDataLoads = data.state.getWorkResults$(id).pipe(mapTo('data-visible'));
  const hideOnError = data.state.isSuccess$(id).pipe(
    filter((v) => !v),
    mapTo('data-hidden'),
  );
  return merge(showWhenDataLoads, hideOnError).pipe(startWith<ReloadVisibility>('data-hidden'));
}
