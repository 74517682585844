<!-- TODO: Move sender chooser to email-senders.component so it can handle the URL updates -->
<email-sender-chooser [initialSelection]="inputSelection" (selection)="handleSenderSelection($event)" />
<ng-container *ngIf="!!selectorSelection?.senderId; else enterSelection">
  <email-domain-authorization [senderType]="selectorSelection.senderType" [senderID]="selectorSelection.senderId" />
</ng-container>

<ng-template #enterSelection>
  <glxy-empty-state>
    <glxy-empty-state-hero>
      <mat-icon>search</mat-icon>
    </glxy-empty-state-hero>
    <glxy-empty-state-title>Choose a sender ID above</glxy-empty-state-title>
    <p>The domain records for that sender will load here</p>
  </glxy-empty-state>
</ng-template>
