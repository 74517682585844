import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { EmailPreferencesSource } from './email-preferences.source';
import { EmailCategoriesSource } from './email-categories.source';

@NgModule({
  imports: [CommonModule],
  exports: [],
  providers: [EmailPreferencesSource, EmailCategoriesSource],
})
export class DataSourcesModule {}
