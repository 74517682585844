<glxy-page>
  <glxy-page-toolbar>
    <glxy-page-nav-button previousPageTitle="Previous Page" previousPageUrl="/sendgrid" />
    <glxy-page-title>SendGrid Admins</glxy-page-title>
  </glxy-page-toolbar>
  <glxy-page-wrapper>
    <glxy-alert type="tip" [showAction]="true" actionTitle="Reload" (actionClick)="handleTryAgain()">
      These are people who can probably help you gain access to SendGrid
    </glxy-alert>
    <div>
      <uikit-async-ui
        [loading]="loading$ | async"
        [error]="failed$ | async"
        [data]="data$ | async"
        [reloadVisibility]="reloadVis$ | async"
      >
        <ng-container *successData="let data">
          <app-sendgrid-admins-table [dataSource]="data" />
        </ng-container>

        <ng-container error *ngTemplateOutlet="errorTemplate" />
      </uikit-async-ui>
    </div>
  </glxy-page-wrapper>
</glxy-page>

<ng-template #errorTemplate>
  <glxy-empty-state size="small">
    <glxy-empty-state-hero>
      <mat-icon>error_outline</mat-icon>
    </glxy-empty-state-hero>
    <glxy-empty-state-title>Unable to load Teammates</glxy-empty-state-title>
    <glxy-empty-state-actions>
      <button mat-raised-button (click)="handleTryAgain()">Try Again</button>
    </glxy-empty-state-actions>
  </glxy-empty-state>
</ng-template>
