import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import {
  DomainService,
  SenderDomain,
  VerifyDefaultDomainResponse,
  VerifyPendingEmailDomainResponse as VerifyResponse,
} from '@vendasta/email';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { EMPTY, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, delay, map, tap } from 'rxjs/operators';
import { RecordsRow } from '../domain-records-table/domain-records-table.component';
import { ExtendedDomain, SenderID } from '../shared-types';
import { SETUP_HELP_INFO_URL_INJECTION_TOKEN } from './dependencies';
import { MatDialog } from '@angular/material/dialog';

export type VerifyError = boolean;

@Component({
  selector: 'email-domain-info-card',
  templateUrl: './domain-info-card.component.html',
  styleUrls: ['./domain-info-card.component.scss'],
})
export class DomainInfoCardComponent implements OnInit {
  private readonly inputs$$ = new ReplaySubject<SenderID>(1);
  private _senderId: SenderID;

  private _domain: SenderDomain;
  targetDmarc: RecordsRow;
  targetSpf: RecordsRow;
  targetA: RecordsRow;
  targetCNAME: RecordsRow;
  targetDKIMs: RecordsRow[];
  domainResult$: Observable<VerifyResponse | VerifyDefaultDomainResponse | VerifyError> = EMPTY;

  @Output() readonly useAsDefaultRequested = new EventEmitter<string>();
  @Output() readonly deleteRequested = new EventEmitter<string>();

  @Input() expanded = false;
  @Input() showShadow = false;
  @Input() showDeleteButton = true;
  @Input() deleteInProgress = false;

  @Input() set senderId(v: SenderID) {
    this.inputs$$.next(v);
    this._senderId = v;
  }

  get senderId(): SenderID {
    return this._senderId;
  }

  @Input() set domain(v: ExtendedDomain) {
    this._domain = v;
    if (v.targetA) {
      this.targetA = {
        recordType: 'A_RECORD',
        recordHost: `@.${v.sendFromDomain}`,
        recordValue: v.targetA,
      };
    }
    if (v.targetCNAME) {
      this.targetCNAME = {
        recordType: 'CNAME',
        recordHost: `www.${v.sendFromDomain}`,
        recordValue: v.targetCNAME,
      };
    }
    this.targetDmarc = {
      recordType: 'TXT',
      recordHost: v.targets.dmarcSubdomain,
      recordValue: v.targets.dmarcValue,
    };
    this.targetSpf = {
      recordType: 'TXT',
      recordHost: `@.${v.sendFromDomain}`,
      recordValue: `v=spf1 ${v.targetSpf} -all`,
    };
    this.targetDKIMs = [
      {
        recordType: 'CNAME',
        recordHost: v.targets.mailSubdomain,
        recordValue: v.targets.mailValue,
      },
      {
        recordType: 'CNAME',
        recordHost: v.targets.dkim1Subdomain,
        recordValue: v.targets.dkim1Value,
      },
      {
        recordType: 'CNAME',
        recordHost: v.targets.dkim2Subdomain,
        recordValue: v.targets.dkim2Value,
      },
    ];
  }

  get domain(): SenderDomain {
    return this._domain;
  }

  @ViewChild('genericHelpModal') helpModal: TemplateRef<any>;
  private readonly dialog = inject(MatDialog);

  constructor(
    private readonly sdk: DomainService,
    private readonly alerts: SnackbarService,
    @Inject(SETUP_HELP_INFO_URL_INJECTION_TOKEN) @Optional() private readonly helpArticleURL: string,
  ) {}

  ngOnInit(): void {
    this.runValidation(true);
  }

  runValidation(initialLoad = false): void {
    if (this._domain.isPrimary) {
      this.runPrimaryDomainValidation(initialLoad);
    } else {
      this.runPendingDomainValidation(initialLoad);
    }
  }

  private runPrimaryDomainValidation(initialLoad: boolean): void {
    const request$ = this.sdk.verifyDefaultDomain(this._senderId.senderType, this._senderId.senderID);
    this.runGenericValidation(request$, initialLoad);
  }

  private runPendingDomainValidation(initialLoad: boolean): void {
    const request$ = this.sdk.verifyPendingDomain(this._senderId.senderType, this._senderId.senderID);
    this.runGenericValidation(request$, initialLoad);
  }

  private runGenericValidation(
    request$: Observable<VerifyResponse | VerifyDefaultDomainResponse>,
    initialLoad: boolean,
  ): void {
    if (!initialLoad) {
      request$ = request$.pipe(
        tap((v) => {
          if (v.allRecordsMatch) {
            this.alerts.openSuccessSnack('DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_VALIDATION.SUCCESS');
          } else {
            this.alerts.openErrorSnack('DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_VALIDATION.FAILURE');
          }
        }),
        catchError((err) => {
          this.alerts.openErrorSnack('DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_VALIDATION.ERROR');
          return throwError(() => err);
        }),
      );
    }
    this.domainResult$ = request$.pipe(
      map((v) => {
        if (v.allRecordsMatch) {
          v.mismatches = [];
        }
        return v;
      }),
      delay(350), // Causes the loading spinner to render for a moment. Less jerky UX.
    );
  }

  openHelpArticle(): void {
    if (this.helpArticleURL) {
      window.open(this.helpArticleURL, '_blank');
    } else {
      this.dialog.open(this.helpModal);
    }
  }
}
