<mat-card appearance="outlined" class="spacing">
  <mat-card-header>
    <mat-card-title>{{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_CARD.GENERAL' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <glxy-alert *ngIf="showAlertBanner" type="info" class="spacing" [showClose]="true" (close)="closeAlert()">
      {{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.CONFIGURATION_ALERT_MESSAGE' | translate }}
    </glxy-alert>
    <form [formGroup]="senderInfoForm" class="spacing">
      <glxy-form-field class="input-padding">
        <glxy-label>{{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.NAME' | translate }}</glxy-label>
        <input type="text" matInput formControlName="senderName" autocomplete="off" />
        <glxy-error *ngIf="senderInfoForm.get('senderName').invalid && senderInfoForm.touched">
          {{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.NAME_REQUIRED' | translate }}
        </glxy-error>
      </glxy-form-field>

      <glxy-form-field [bottomSpacing]="false" class="input-padding">
        <glxy-label>{{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.REPLY_ADDRESS' | translate }}</glxy-label>
        <input type="text" matInput formControlName="replyTo" autocomplete="off" />
        <glxy-error *ngIf="senderInfoForm.get('replyTo').invalid && senderInfoForm.touched">
          {{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.REPLY_ADDRESS_NOT_VALID' | translate }}
        </glxy-error>
      </glxy-form-field>
    </form>
    <button
      mat-raised-button
      color="primary"
      [disabled]="(saveSenderInfoRequest$ | glxyAsyncStatus | async).status === 'loading'"
    >
      <glxy-button-loading-indicator
        (click)="handleSaveRequested(getSaveData(), false)"
        [isLoading]="(saveSenderInfoRequest$ | glxyAsyncStatus | async).status === 'loading'"
      >
        {{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_CARD.SAVE' | translate }}
      </glxy-button-loading-indicator>
    </button>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-header>
    <mat-card-title>{{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.TITLE' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="senderInfoForm" class="spacing">
      <glxy-form-field>
        <glxy-label>{{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.SENDER_DOMAIN' | translate }}</glxy-label>
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          formControlName="useOwn"
        >
          <mat-radio-button [value]="false">
            {{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.USE_DEFAULT' | translate }}
          </mat-radio-button>
          <ng-container *ngIf="useOwn === false; then usernameinput"></ng-container>
          <mat-radio-button [value]="true">
            {{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.USE_OWN' | translate }}
          </mat-radio-button>
          <ng-container *ngIf="useOwn === true; then usernameinput"></ng-container>
        </mat-radio-group>
      </glxy-form-field>

      <ng-template #cta>
        <glxy-empty-state>
          <p>{{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.EMPTY_DOMAIN' | translate }}</p>
          <glxy-empty-state-actions>
            <button *ngIf="(domains$ | async)?.length > 0" mat-raised-button (click)="openChooseDomainDialog()">
              <span>{{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.CHOOSE_DOMAIN' | translate }}</span>
            </button>
            <button *ngIf="(domains$ | async)?.length === 0" mat-raised-button (click)="handleAuthorizeDomain()">
              {{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.ADD_DOMAIN' | translate }}
            </button>
          </glxy-empty-state-actions>
        </glxy-empty-state>
      </ng-template>

      <ng-template #usernameinput>
        <div class="email" *ngIf="(selectedDomain$ | async)?.canUse; else cta">
          <glxy-form-field [bottomSpacing]="false" class="input-padding">
            <email-username-input
              [domain$]="selectedDomainName$"
              [sender]="sender"
              [form]="senderInfoForm"
              (authorizeRequested)="handleAuthorizeDomain()"
              (domainChangeRequested)="this.domainChangeRequested.emit($event)"
              (invalidDomainChangeRequested)="this.invalidDomainChangeRequested.emit($event)"
              formControlNameString="senderEmailUsername"
            ></email-username-input>
          </glxy-form-field>
        </div>
      </ng-template>
    </form>
    @if (showForwardingAddress) {
      <glxy-form-field class="input-padding">
        <glxy-label>{{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.FORWARDING_ADDRESS' | translate }}</glxy-label>
        <input disabled type="text" placeholder="{{ this.forwardingAddress | async }}" matInput />
        <button
          mat-icon-button
          matSuffix
          (click)="copyClicked()"
          [glxyTooltip]="
            (isCopied
              ? 'DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.COPY_SUCCESS'
              : 'DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.CLICK_TO_COPY'
            ) | translate
          "
        >
          <mat-icon>content_copy</mat-icon>
        </button>
        <glxy-hint>
          {{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.FORWARDING_ADDRESS_DESCRIPTION' | translate }}
          <a (click)="openLearnMoreModal()">{{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_FORM.LEARN_MORE' | translate }}</a>
        </glxy-hint>
      </glxy-form-field>
    }
    <button
      mat-raised-button
      color="primary"
      [disabled]="(saveDomainRequest$ | glxyAsyncStatus | async).status === 'loading'"
    >
      <glxy-button-loading-indicator
        (click)="handleSaveRequested(getSaveData(), true)"
        [isLoading]="(saveDomainRequest$ | glxyAsyncStatus | async).status === 'loading'"
      >
        {{ 'DOMAIN_AUTHORIZATION.SENDER_INFO_CARD.SAVE' | translate }}
      </glxy-button-loading-indicator>
    </button>
  </mat-card-content>
</mat-card>
