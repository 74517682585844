import { GetSenderInfoResponseInterface } from '@vendasta/email';

export class SelectedDomain {
  readonly canUse: boolean;

  private static decideCanUse(
    domain: string,
    senderInfo: GetSenderInfoResponseInterface,
    useDefault: boolean,
  ): boolean {
    if (useDefault) {
      return true;
    }
    if (!domain) {
      return false;
    }
    return domain === senderInfo.domain && senderInfo.isPlatformAuthorized;
  }

  constructor(
    public readonly domain: string,
    senderInfo: GetSenderInfoResponseInterface,
    useDefault: boolean,
  ) {
    this.canUse = SelectedDomain.decideCanUse(domain, senderInfo, useDefault);
  }
}
