import { Pipe, PipeTransform } from '@angular/core';
import { SenderDomain, SenderType } from '@vendasta/email';
import { Observable, of } from 'rxjs';
import { DefaultDomainService } from '../default-domain.service';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'canAddDomain',
})
export class CanAddDomainPipe implements PipeTransform {
  constructor(private defaultDomainService: DefaultDomainService) {}

  transform(value: SenderDomain[], senderType: SenderType): Observable<boolean> {
    if (!value) {
      return of(true);
    }
    return this.defaultDomainService.getDefaultDomain$(senderType).pipe(
      map((defaultDomain) => {
        const nonDefault = value.filter((d) => d.sendFromDomain !== defaultDomain);
        const hasPending = nonDefault.map((d) => !d.isPrimary).some((isPending) => isPending);
        return hasPending === false;
      }),
    );
  }
}
