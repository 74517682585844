<mat-card-header>
  <mat-card-title class="search-title">Unbounce email recipients</mat-card-title>
</mat-card-header>
<ng-container *ngIf="(searchResult$$ | async) === null; else resultComponent">
  <mat-card-content>
    <EXP__glxy-wrap>
      <mat-form-field>
        <mat-label>What kind of sender?</mat-label>
        <mat-select
          id="sender-type"
          placeholder="Sender Type"
          [(value)]="senderType"
          (selectionChange)="typeChanged($event)"
        >
          <mat-option value="business">Business</mat-option>
          <mat-option value="partner">Partner</mat-option>
          <mat-option value="yesware">Yesware</mat-option>
        </mat-select>
      </mat-form-field>
    </EXP__glxy-wrap>

    <form [formGroup]="searchForm">
      <glxy-input *ngIf="senderType === 'partner'" placeholder="Partner ID" formControlName="senderId" />
      <glxy-input *ngIf="senderType === 'business'" placeholder="Business ID" formControlName="senderId" />
      <glxy-input placeholder="Recipient Email" formControlName="email" />

      <button
        mat-flat-button
        color="primary"
        class="search-button"
        type="button"
        [disabled]="loading$$ | async"
        (click)="searchForBounced()"
      >
        <glxy-button-loading-indicator [isLoading]="loading$$ | async">Search</glxy-button-loading-indicator>
      </button>
    </form>
  </mat-card-content>
</ng-container>

<ng-template #resultComponent>
  <ng-container *ngIf="searchResult$$ | async as result">
    <mat-card-header>
      <mat-card-title>Search result:</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="result.senderId !== '' && result.senderId !== '~'">Sender ID: {{ result.senderId }}</div>
      <div>Recipient Email: {{ result.email }}</div>
    </mat-card-content>

    <mat-card-actions>
      <button mat-flat-button color="primary" (click)="goBack()">Go back</button>
      <button mat-flat-button color="primary" [disabled]="loading$$ | async" (click)="unbounceRecipient(result)">
        <glxy-button-loading-indicator [isLoading]="loading$$ | async">Unbounce</glxy-button-loading-indicator>
      </button>
    </mat-card-actions>
  </ng-container>
</ng-template>
