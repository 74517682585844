import { Injectable } from '@angular/core';
import { DataProviderConfig, KeyedDataProvider } from '@vendasta/data-providers';
import { Category, GetCategoriesResponse as Response, PreferencesService, SenderType } from '@vendasta/email';
import { of, throwError } from 'rxjs';

export interface CategoriesInputs {
  senderType: SenderType;
  senderId: string;
}

@Injectable()
export class EmailCategoriesSource extends KeyedDataProvider<CategoriesInputs, Response, Category[]> {
  constructor(svc: PreferencesService) {
    const cfg: DataProviderConfig<CategoriesInputs, Response, Category[]> = {
      externalSource: {
        fetchFn: (i: CategoriesInputs) => svc.getCategories(i.senderType, i.senderId),
        uploadFn: () => throwError('Upload not supported'),
      },
      convert: {
        apiDataToAppData: (r: Response) => of(r.categories),
        inputsToStringKey: (i) => i,
      },
    };
    super(cfg);
  }
}
