<ng-container *ngIf="domains?.length > 0; else empty">
  <ng-container *ngFor="let domain of domains">
    <email-domain-info-card
      [attr.id]="'domain_card_' + domain.sendFromDomain"
      [senderId]="senderId"
      [domain]="domain"
      [deleteInProgress]="deleteInProgress"
      (useAsDefaultRequested)="useAsDefaultRequested.emit($event)"
      (deleteRequested)="deleteRequested.emit($event)"
    ></email-domain-info-card>
  </ng-container>
  <div *ngIf="domains | canAddDomain: senderId.senderType | async" class="add-pending">
    <button mat-raised-button (click)="handleAuthorizeClick()">
      <span>{{ 'DOMAIN_AUTHORIZATION.DOMAINS.ADDITIONAL_DOMAIN' | translate }}</span>
    </button>
  </div>
</ng-container>

<ng-template #empty>
  <glxy-empty-state *ngIf="showEmpty">
    <glxy-empty-state-hero>
      <mat-icon>domain_disabled</mat-icon>
    </glxy-empty-state-hero>
    <glxy-empty-state-title>{{ 'DOMAIN_AUTHORIZATION.DOMAINS.NO_DOMAINS' | translate }}</glxy-empty-state-title>
    <p>{{ 'DOMAIN_AUTHORIZATION.DOMAINS.NEW_DOMAIN' | translate }}</p>
    <glxy-empty-state-actions>
      <button mat-raised-button color="primary" (click)="handleAuthorizeClick()">
        {{ 'DOMAIN_AUTHORIZATION.DOMAINS.AUTH_DOMAIN_BUTTON' | translate }}
      </button>
    </glxy-empty-state-actions>
  </glxy-empty-state>
</ng-template>
