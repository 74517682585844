import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetPendingEmailDomainResponseInterface } from '@vendasta/email';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { DomainCacheService } from '../domain-cache.service';
import { SenderID } from '../shared-types';

export interface NewDomainDialogData {
  senderID: SenderID;
}

export interface Result {
  newPendingDomain?: string;
}

@Component({
  selector: 'email-new-domain-dialog',
  templateUrl: './new-domain-dialog.component.html',
  styleUrls: ['./new-domain-dialog.component.scss'],
})
export class NewDomainDialogComponent {
  readonly senderForm = new UntypedFormGroup({
    senderDomain: new UntypedFormControl('', [Validators.required, Validators.pattern('[^(@\\s)]+\\.[^(@\\s)]+')]),
  });
  authorizedDomain$: Observable<GetPendingEmailDomainResponseInterface> = of(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: NewDomainDialogData,
    @Inject(MatDialogRef) private readonly dialog: MatDialogRef<NewDomainDialogComponent>,
    private readonly domainService: DomainCacheService,
    private readonly alerts: SnackbarService,
  ) {}

  private authorize(): void {
    const domain = this.senderForm.get('senderDomain').value;
    this.authorizedDomain$ = this.domainService
      .createPendingDomain(this.data.senderID.senderType, this.data.senderID.senderID, domain)
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        catchError(() => {
          this.alerts.openErrorSnack('DOMAIN_AUTHORIZATION.NEW_DOMAIN_DIALOG.AUTH_ERROR');
          return of(null);
        }),
        tap(() =>
          this.dialog.close({
            newPendingDomain: domain,
          } as Result),
        ),
      );
  }

  close(): void {
    this.dialog.close({ newPendingDomain: undefined } as Result);
  }

  submitForm(): void {
    this.senderForm.markAsDirty();
    if (this.senderForm.valid) {
      this.authorize();
    } else {
      this.alerts.openErrorSnack('DOMAIN_AUTHORIZATION.NEW_DOMAIN_DIALOG.INVALID_ERROR');
    }
  }
}
