import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomainRecordsComponent } from './domain-records.component';
import { SenderChooserModule } from '@galaxy/email-ui/sender-chooser';
import { DomainAuthorizationModule } from '@galaxy/email-ui/domain-authorization';
import { GalaxyEmptyStateModule } from '@vendasta/galaxy/empty-state';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [DomainRecordsComponent],
  imports: [CommonModule, SenderChooserModule, DomainAuthorizationModule, GalaxyEmptyStateModule, MatIconModule],
  exports: [DomainRecordsComponent],
})
export class DomainRecordsModule {}
