import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Category } from '@vendasta/email';

export interface UICategory {
  categoryId: string;
  categoryName: string;
  isOptedIn: boolean;
}

@Component({
  selector: 'email-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
})
export class PreferencesComponent implements OnInit, OnChanges {
  @Input() categories: Category[] = [];
  @Input() preferenceIDs: string[] = [];
  @Input() allowEditing = false;

  @Output() selectedCategoryIDs: EventEmitter<string[]> = new EventEmitter<string[]>();

  formGroup = new UntypedFormGroup({
    preferences: new UntypedFormArray([]),
  });

  data: UICategory[] = [];

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  get prefFields(): UntypedFormArray {
    return this.formGroup.controls['preferences'] as UntypedFormArray;
  }

  ngOnInit(): void {
    const p = this.prefFields;
    const control = this.formBuilder.control(false);
    p.push(control);
    this.handleInputChanges();
  }

  ngOnChanges(): void {
    this.handleInputChanges();
  }

  private handleInputChanges(): void {
    const combined = applyPreferencesToCategories(this.preferenceIDs, this.categories);
    this.data = combined;
    this.prefFields.clear();
    combined.forEach((p: UICategory) => {
      this.prefFields.push(this.formBuilder.control(p.isOptedIn));
    });
    this.selectedCategoryIDs.emit(this.getOptedInCategoryIDs(combined));
  }

  private getOptedInCategoryIDs(categories: UICategory[]): string[] {
    return categories.filter((c) => c.isOptedIn).map((c) => c.categoryId);
  }

  public toggleSelection(i: number): void {
    this.data[i].isOptedIn = !this.data[i].isOptedIn;
    const optedIn = this.getOptedInCategoryIDs(this.data);
    this.selectedCategoryIDs.emit(optedIn);
  }
}

export function applyPreferencesToCategories(
  recipientPreferenceIDs: string[],
  senderCategories: Category[],
): UICategory[] {
  const repo = {};
  const cats = {};
  recipientPreferenceIDs.forEach((id: string) => (repo[id] = true));
  senderCategories.forEach((cat: Category) => (cats[cat.id] = true));
  const unknownPrefs = recipientPreferenceIDs
    .filter((id) => !cats[id])
    .map((id) => ({ id: id, name: `Unknown (id:${id})` } as Category));
  const all = senderCategories.concat(unknownPrefs);
  return all.map(
    (v: Category) =>
      ({
        categoryId: v.id,
        categoryName: v.name,
        isOptedIn: repo[v.id] || false,
      } as UICategory),
  );
}
