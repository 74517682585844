import { DataSource } from '@angular/cdk/collections';
import { PageEvent } from '@angular/material/paginator';
import { EmailMessage, EmailService, ListEmailsRequestListEmailsFilters, PagedRequestOptions } from '@vendasta/email';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { FilterField } from '@vendasta/uikit';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

interface PageOptions {
  index: number;
  size: number;
}

export class EmailDataSource extends DataSource<EmailMessage> {
  private _emails$$: ReplaySubject<EmailMessage[]> = new ReplaySubject(1);
  private _searchTerm$$: BehaviorSubject<string> = new BehaviorSubject('');
  private _pageOptions$$: BehaviorSubject<PageOptions> = new BehaviorSubject({ index: 0, size: 15 });
  private _toFilter$$: BehaviorSubject<string> = new BehaviorSubject('');
  private _fromFilter$$: BehaviorSubject<string> = new BehaviorSubject('');
  private _startFilter$$: BehaviorSubject<Date> = new BehaviorSubject(null);
  private _endFilter$$: BehaviorSubject<Date> = new BehaviorSubject(null);
  private _espFilter$$: BehaviorSubject<string> = new BehaviorSubject('');
  private _partnerIDFilter$$: BehaviorSubject<string> = new BehaviorSubject('');
  private _applicationIDFilter$$: BehaviorSubject<string> = new BehaviorSubject('');
  private _businessIDFilter$$: BehaviorSubject<string> = new BehaviorSubject('');

  emails$: Observable<EmailMessage[]> = this._emails$$.asObservable();
  searchTerm$: Observable<string> = this._searchTerm$$.asObservable();
  pageSize$: Observable<number>;

  totalResults = 0;

  constructor(private _api: EmailService, private snackBarService: SnackbarService) {
    super();
    this.pageSize$ = this._pageOptions$$.asObservable().pipe(map((p) => p.size));
  }

  private static _getFilter(
    to: string,
    from: string,
    start: Date,
    end: Date,
    espId: string,
    partnerId?: string,
    applicationId?: string,
    businessId?: string,
  ): ListEmailsRequestListEmailsFilters {
    return new ListEmailsRequestListEmailsFilters({
      to: to,
      from: from,
      dateRange: {
        start: start,
        end: end,
      },
      espId: espId,
      partnerId: partnerId,
      applicationId: applicationId,
      businessId: businessId,
    });
  }

  pageOptionsChanged(event: PageEvent): void {
    this._pageOptions$$.next({ index: event.pageIndex, size: event.pageSize });
  }

  private _getCursor(pageIndex: number, pageSize: number): string {
    const offset = pageSize * pageIndex;
    return window.btoa(offset.toString(10));
  }

  private _getPagingRequestOptions(pagingOptions: PageOptions): PagedRequestOptions {
    return new PagedRequestOptions({
      cursor: this._getCursor(pagingOptions.index, pagingOptions.size),
      pageSize: pagingOptions.size,
    });
  }

  updateSearchTerm(term: string): void {
    this._searchTerm$$.next(term);
  }

  updateFilter(field: FilterField<any>): void {
    switch (field.id) {
      case 'to':
        this._toFilter$$.next(field.value);
        break;
      case 'from':
        this._fromFilter$$.next(field.value);
        break;
      case 'sent_after':
        this._startFilter$$.next(field.value);
        break;
      case 'sent_before':
        this._endFilter$$.next(field.value);
        break;
      case 'email_service_provider':
        this._espFilter$$.next(field.value?.id);
        break;
      case 'partner_id':
        this._partnerIDFilter$$.next(field.value);
        break;
      case 'application_id':
        this._applicationIDFilter$$.next(field.value);
        break;
      case 'business_id':
        this._businessIDFilter$$.next(field.value);
        break;
    }
  }

  connect(): Observable<EmailMessage[]> {
    combineLatest([
      this.searchTerm$,
      this._pageOptions$$,
      this._toFilter$$.asObservable(),
      this._fromFilter$$.asObservable(),
      this._startFilter$$.asObservable(),
      this._endFilter$$.asObservable(),
      this._espFilter$$.asObservable(),
      this._partnerIDFilter$$.asObservable(),
      this._applicationIDFilter$$.asObservable(),
      this._businessIDFilter$$.asObservable(),
    ])
      .pipe(
        switchMap(([searchTerm, pageOptions, to, from, start, end, espId, partnerID, applicationID, businessID]) => {
          return this._api
            .listEmails(
              <string>searchTerm,
              EmailDataSource._getFilter(
                <string>to,
                <string>from,
                <Date>start,
                <Date>end,
                <string>espId,
                <string>partnerID,
                <string>applicationID,
                <string>businessID,
              ),
              this._getPagingRequestOptions(<PageOptions>pageOptions),
            )
            .pipe(
              catchError(() => {
                this.snackBarService.openErrorSnack('An error occurred, please try again.');
                return of(null);
              }),
            );
        }),
      )
      .subscribe((r) => {
        if (r) {
          this._emails$$.next(r.emails);
          this.totalResults = r.pagingMetadata.totalResults;
        } else {
          this._emails$$.next([]);
        }
      });
    return this.emails$;
  }

  disconnect(): void {
    return;
  }
}
