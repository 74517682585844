import { Directive, HostListener, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';

@Directive({
  selector: '[appCopyToClipboard]',
})
export class CopyToClipboardDirective {
  @Input('appCopyToClipboard') fieldText: string;

  constructor(private snackbarService: SnackbarService, private translateService: TranslateService) {}

  @HostListener('click')
  copyToClipboardOnClick(): void {
    try {
      const listener = (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', this.fieldText);
        e.preventDefault();
      };
      document.addEventListener('copy', listener);
      document.execCommand('copy');
      document.removeEventListener('copy', listener);
    } catch (error) {
      this.snackbarService.errorSnack(
        `${this.translateService.instant('COPY_TO_CLIPBOARD.COPY')}: "${this.fieldText}"`,
      );
      return;
    }
    this.snackbarService.successSnack(
      `${this.translateService.instant('COPY_TO_CLIPBOARD.COPIED')}: "${this.fieldText}"`,
    );
  }
}
