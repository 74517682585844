import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ResubscribeComponent } from './resubscribe.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GalaxyAlertModule } from '@vendasta/galaxy/alert';
import { GalaxyPopoverModule } from '@vendasta/galaxy/popover';
import { GalaxyInputModule } from '@vendasta/galaxy/input';
import { GalaxyWrapModule } from '@vendasta/galaxy/galaxy-wrap';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    GalaxyAlertModule,
    GalaxyPopoverModule,
    GalaxyInputModule,
    GalaxyWrapModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCardModule,
  ],
  declarations: [ResubscribeComponent],
  exports: [ResubscribeComponent],
})
export class ResubscribeModule {}
