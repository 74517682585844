import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomainAuthorizationComponent } from './domain-authorization.component';
import { DomainCacheService } from './domain-cache.service';

import { GalaxyEmptyStateModule } from '@vendasta/galaxy/empty-state';
import { MatIconModule } from '@angular/material/icon';
import { GalaxyPipesModule } from '@vendasta/galaxy/pipes';
import { GalaxyLoadingSpinnerModule } from '@vendasta/galaxy/loading-spinner';
import { DomainsModule } from './domains/domains.module';
import { SenderInfoCardModule } from './sender-info-card/sender-info-card.module';
import { EmailUII18nModule } from '../../src/lib/i18n/email-ui-i18n.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DomainAuthorizationComponent],
  imports: [
    CommonModule,
    GalaxyEmptyStateModule,
    MatIconModule,
    GalaxyPipesModule,
    GalaxyLoadingSpinnerModule,
    DomainsModule,
    SenderInfoCardModule,
    EmailUII18nModule,
    TranslateModule,
  ],
  providers: [DomainCacheService],
  exports: [DomainAuthorizationComponent],
})
export class DomainAuthorizationModule {}
