import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Injectable, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SavedState, UNSAVED_DATA_HANDLER_TOKEN, UnsavedDataHandler } from '@galaxy/email-ui/domain-authorization';
import { FormPayload } from '@galaxy/email-ui/sender-chooser';
import { SenderType } from '@vendasta/email';
import { ComponentCanDeactivate } from '../../../../core/pending-changes.guard';

@Injectable()
class UnsavedChanges implements UnsavedDataHandler {
  private hasUnsavedChanges = false;

  canDeactivate(): boolean {
    return !this.hasUnsavedChanges;
  }

  notifyStateChanged(newState: SavedState): void {
    switch (newState) {
      case SavedState.SAFE_TO_NAVIGATE_AWAY:
        this.hasUnsavedChanges = false;
        return;
      case SavedState.HAS_UNSAVED_CHANGES:
        this.hasUnsavedChanges = true;
        return;
      default:
        throw new Error(`Unexpected SavedState: ${newState}`);
    }
  }
}

@Component({
  selector: 'app-domain-records',
  templateUrl: './domain-records.component.html',
  styleUrls: ['./domain-records.component.scss'],
  providers: [UnsavedChanges, { provide: UNSAVED_DATA_HANDLER_TOKEN, useExisting: UnsavedChanges }],
})
export class DomainRecordsComponent extends ComponentCanDeactivate {
  @Input()
  set selection(v: { senderType: SenderType; senderId: string }) {
    this.inputSelection = v;
  }

  inputSelection: FormPayload;
  selectorSelection: FormPayload;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly cd: ChangeDetectorRef,
    private readonly unsavedChanges: UnsavedChanges,
  ) {
    super();
  }

  canDeactivate(): boolean {
    return this.unsavedChanges.canDeactivate();
  }

  handleSenderSelection(event: FormPayload): void {
    if (!event) {
      return;
    }
    this.selectorSelection = event;
    this.cd.detectChanges();
    const tree = this.router.createUrlTree([`email`, `senders`, event.senderType, event.senderId]);
    this.location.go(tree.toString());
  }
}
