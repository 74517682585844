import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UnbounceComponent } from './unbounce.component';
import { GalaxyInputModule } from '@vendasta/galaxy/input';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GalaxyWrapModule } from '@vendasta/galaxy/galaxy-wrap';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { GalaxyAlertModule } from '@vendasta/galaxy/alert';
import { GalaxyButtonLoadingIndicatorModule } from '@vendasta/galaxy/button-loading-indicator';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GalaxyInputModule,
    MatCardModule,
    MatProgressSpinnerModule,
    FormsModule,
    GalaxyWrapModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    GalaxyAlertModule,
    GalaxyButtonLoadingIndicatorModule,
  ],
  declarations: [UnbounceComponent],
  exports: [UnbounceComponent],
})
export class UnbounceModule {}
