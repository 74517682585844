import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailCategoriesSource, EmailPreferencesSource } from '@galaxy/email-ui/preferences/data-sources';
import { Category, GetCategoryPreferencesResponseInterface, SenderType } from '@vendasta/email';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

interface FormPayload {
  senderType: SenderType;
  senderId: string;
  userId: string;
}

@Component({
  templateUrl: './email-users.component.html',
  styleUrls: ['./email-users.component.scss'],
})
export class EmailUsersComponent {
  readonly partnerSenderType = SenderType.SENDER_TYPE_PARTNER;

  readonly searchForm = new UntypedFormGroup({
    senderType: new UntypedFormControl(SenderType.SENDER_TYPE_PARTNER, [Validators.required]),
    senderId: new UntypedFormControl('', [Validators.required]),
    userId: new UntypedFormControl('', [Validators.required]),
  });

  private readonly selection$ = this.searchForm.valueChanges.pipe(
    filter((v: FormPayload) => v.senderType !== SenderType.SENDER_TYPE_INVALID && !!v.senderId && !!v.userId),
    map((v: FormPayload) => v),
    debounceTime(1000),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  private readonly preferences$ = this.forSelection((v: FormPayload) => this.preferences.getCachedOrFetch$(v));
  private readonly prefsLoading$ = this.forSelection((v: FormPayload) => this.preferences.isLoading$(v));
  private readonly prefsFailed$ = this.forSelection((v: FormPayload) => this.preferences.isFailed$(v));

  private readonly categories$ = this.forSelection((v: FormPayload) => this.categories.getCachedOrFetch$(v));
  private readonly catsLoading$ = this.forSelection((v: FormPayload) => this.categories.isLoading$(v));
  private readonly catsFailed$ = this.forSelection((v: FormPayload) => this.categories.isFailed$(v));

  readonly loading$ = combineLatest([this.prefsLoading$, this.catsLoading$]).pipe(
    map(([l1, l2]) => l1 || l2),
    startWith(false),
  );

  readonly loadStyle$ = combineLatest([this.prefsLoading$, this.catsLoading$]).pipe(
    map(([l1, l2]) => (!l1 || !l2 ? 'data-visible' : 'data-hidden')),
  );

  readonly failed$ = combineLatest([this.prefsFailed$, this.catsFailed$]).pipe(
    map(([f1, f2]) => f1 || f2),
    startWith(false),
  );

  readonly data$ = combineLatest([this.preferences$, this.categories$.pipe(startWith([]))]).pipe(
    map(([prefs, cats]: [GetCategoryPreferencesResponseInterface, Category[]]) => {
      if (!prefs.emailPreferenceInitialized) {
        return null;
      }
      return { preferenceIds: prefs.optedInEmailCategoryIds, categories: cats };
    }),
  );

  readonly noPreferences$ = this.preferences$.pipe(map((p) => !p.emailPreferenceInitialized));

  readonly showEmptyState$ = combineLatest([this.loading$, this.data$.pipe(startWith([])), this.failed$]).pipe(
    map(([loading, data, failed]: [boolean, { categories: Category[]; preferenceIds: string[] }, boolean]) => {
      if (failed || loading) {
        return false;
      }
      return data?.categories?.length === 0 && data?.preferenceIds?.length === 0;
    }),
  );

  private forSelection<X>(fn: (v: FormPayload) => Observable<X>): Observable<X> {
    return this.selection$.pipe(switchMap(fn));
  }

  constructor(
    private readonly preferences: EmailPreferencesSource,
    private readonly categories: EmailCategoriesSource,
  ) {}
}
