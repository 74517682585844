<ng-container *ngIf="!data">
  <p>{{ 'DOMAIN_AUTHORIZATION.NEW_DOMAIN_DIALOG.NOT_PROVIDED' | translate }}</p>
</ng-container>

<h2 mat-dialog-title>
  {{ 'DOMAIN_AUTHORIZATION.NEW_DOMAIN_DIALOG.TITLE' | translate }}
</h2>

<mat-dialog-content>
  <p>
    {{ 'DOMAIN_AUTHORIZATION.NEW_DOMAIN_DIALOG.HEADER' | translate }}
  </p>

  <form [formGroup]="senderForm" (keyup.enter)="submitForm()">
    <div class="domain">
      <glxy-form-field prefixText="example@">
        <glxy-label>{{ 'COMMON.DOMAIN' | translate }}</glxy-label>
        <input type="text" placeholder="example.com" matInput formControlName="senderDomain" autocomplete="off" />
      </glxy-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button type="button" (click)="close()">
    {{ 'DOMAIN_AUTHORIZATION.NEW_DOMAIN_DIALOG.CANCEL' | translate }}
  </button>

  <button
    mat-raised-button
    type="submit"
    color="primary"
    [disabled]="!senderForm?.valid || (authorizedDomain$ | glxyAsyncStatus | async).status === 'loading'"
  >
    <glxy-button-loading-indicator
      [isLoading]="(authorizedDomain$ | glxyAsyncStatus | async).status === 'loading'"
      (click)="submitForm()"
    >
      {{ 'DOMAIN_AUTHORIZATION.NEW_DOMAIN_DIALOG.START' | translate }}
    </glxy-button-loading-indicator>
  </button>
</mat-dialog-actions>
