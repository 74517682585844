import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LexiconModule } from '@galaxy/lexicon';
import En from './assets/en_devel.json';

@NgModule({
  imports: [
    TranslateModule,
    LexiconModule.forChild({
      componentName: 'common/email-ui',
      baseTranslation: En,
    }),
  ],
})
export class EmailUII18nModule {}
