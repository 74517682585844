<form [formGroup]="searchForm">
  <EXP__glxy-wrap>
    <mat-form-field>
      <mat-label>
        {{ 'SENDER_CHOOSER.SENDER.PLACEHOLDER' | translate }}
      </mat-label>
      <mat-select formControlName="senderType">
        <!-- Leaving these untranslated to preserve integrity with data model -->
        <mat-option [value]="partnerSenderType">Partner</mat-option>
        <mat-option [value]="businessSenderType">Business</mat-option>
      </mat-select>
    </mat-form-field>
  </EXP__glxy-wrap>

  <EXP__glxy-wrap>
    <mat-form-field>
      <mat-label>{{ idLabel$ | async }}</mat-label>
      <input type="text" matInput formControlName="senderId" autocomplete="off" />
    </mat-form-field>
  </EXP__glxy-wrap>
</form>
