import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Environment, EnvironmentService } from '@galaxy/core';
import { EmailService } from '@vendasta/email';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-email-admin',
  templateUrl: 'email-admin.component.html',
  styleUrls: ['email-admin.component.scss'],
})
export class EmailAdminComponent implements OnInit {
  public env: string;

  queueStatus$ = this.emailService.getEmailQueuesStatus().pipe(startWith<any, null>(null));

  setStatus(a): void {
    this.emailService.setEmailQueuesStatus(a.checked).subscribe();
  }

  constructor(public emailService: EmailService, public dialog: MatDialog, public envService: EnvironmentService) {}

  ngOnInit(): void {
    if (this.envService.getEnvironment() === Environment.PROD) {
      this.env = 'prod';
    } else {
      this.env = 'demo';
    }
  }
}
