<va-filter-container
  [filters]="filters"
  [filterIsOpen]="true"
  [initialSearch]="searchTerm"
  (searchTermChanged)="searchTerm = $event"
>
  <table mat-table content [dataSource]="emailDataSource">
    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef>Timestamp</th>
      <td mat-cell *matCellDef="let row">
        {{ row.timestamp | date: 'mediumDate' }}
        <br />
        <span class="small-text">{{ row.timestamp | date: 'mediumTime' }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="partner">
      <th mat-header-cell *matHeaderCellDef>PID</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="getPartnerID(row); else elseBlock">
          {{ getPartnerID(row) }}
        </span>
        <ng-template #elseBlock>
          <div class="no-pid" matTooltip="No PID attached to this email">—</div>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="to">
      <th mat-header-cell *matHeaderCellDef>To</th>
      <td mat-cell *matCellDef="let row" class="hide-overflow">
        <div *ngFor="let t of row.to">
          <span title="{{ t.name }}">{{ t.name }}</span>
          <div class="small-text hide-overflow" title="{{ t.emailAddress }}">
            {{ t.emailAddress }}
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="from">
      <th mat-header-cell *matHeaderCellDef>From</th>
      <td mat-cell *matCellDef="let row" class="hide-overflow">
        <span title="{{ row.from.name }}">{{ row.from.name }}</span>
        <div class="small-text hide-overflow" title="{{ row.from.emailAddress }}">
          {{ row.from.emailAddress }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef>Subject</th>
      <td mat-cell *matCellDef="let row" class="hide-overflow" title="{{ row.subject }}">
        {{ row.subject }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row (click)="openEmailDialog(row)" *matRowDef="let row; columns: displayedColumns" tabindex="-1"></tr>
  </table>
</va-filter-container>

<mat-paginator
  [pageSize]="emailDataSource.pageSize$ | async"
  [pageSizeOptions]="[15, 30, 100]"
  [length]="emailDataSource.totalResults"
  [showFirstLastButtons]="true"
  (page)="emailDataSource.pageOptionsChanged($event)"
/>

<div style="clear: both"></div>
