import { Component, Inject } from '@angular/core';
import { ListAllDomainsResponseInterface, Sender, SenderType } from '@vendasta/email';
import { EMPTY, Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { Data as ButtonData } from './domain-status-and-name/domain-status-and-name.component';
import { DomainCacheService } from '../domain-cache.service';

export type ChoiceType = 'new' | 'valid' | 'invalid';

export interface Result {
  newDomain: string | undefined;
  choiceType: ChoiceType;
}

@Component({
  selector: 'email-domains-dialog',
  templateUrl: './domains-dialog.component.html',
  styleUrls: ['./domains-dialog.component.scss'],
})
export class DomainsDialogComponent {
  allDomains$: Observable<ButtonData[] | boolean> = EMPTY;

  constructor(
    private readonly domainService: DomainCacheService, //
    @Inject(MAT_DIALOG_DATA) sender: Sender,
    readonly self: MatDialogRef<DomainsDialogComponent>,
  ) {
    this.refreshDomains(sender);
  }

  private refreshDomains(sender: Sender): void {
    if (!sender.id || sender.type === SenderType.SENDER_TYPE_INVALID) {
      return;
    }
    // We replace the entire observable because errors cause the original observable to complete
    this.allDomains$ = this.domainService
      .listAllDomains(sender.type, sender.id)
      .pipe(map((v) => convertListResponseToButtonData(sender, v)));
  }

  handleDomainChangeRequested(domain: string, isValidDomain: boolean): void {
    this.self.close({
      newDomain: domain,
      choiceType: isValidDomain ? 'valid' : 'invalid',
    } as Result);
  }

  handleNewDomainRequested(): void {
    this.self.close({
      newDomain: '',
      choiceType: 'new',
    } as Result);
  }
}

function convertListResponseToButtonData(sender: Sender, response: ListAllDomainsResponseInterface): ButtonData[] {
  return response.domains.map(
    (v) =>
      ({
        domain: v,
        sender: sender,
      } as ButtonData),
  );
}
