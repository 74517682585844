{
  "COMMON": {
    "DOMAIN": "Domain",
    "EMAIL_ADDRESS": "Email address",
    "ACTION_LABELS": {
      "ADD_EMAIL": "Add email",
      "EDIT": "Edit",
      "DUPLICATE": "Duplicate",
      "DELETE": "Delete",
      "CANCEL": "Cancel",
      "PREVIEW": "Preview"
    }
  },
  "ADVANCED_SECTION_TITLE": "Advanced",
  "DOMAIN_DELETE": {
    "BUTTON_TEXT": "Delete Email Configuration",
    "DIALOG": {
      "WARNING_TITLE": "Warning",
      "DELETING_INVALIDATES_DOMAIN": "Deleting your Email Configuration will render your domain records invalid and reset your sender address to the default.",
      "MUST_REGISTER_DOMAIN_RECORDS": "This means: if you want to customize your sender address in the future, you will need to repeat the process of configuring domain records and waiting for DNS propagation to occur.",
      "CANCEL_BUTTON_TEXT": "No, keep my Configuration",
      "DELETE_BUTTON_TEXT": "Yes, delete my Email Configuration",
      "DELETE_SUCCESS_ALERT": "Deleted Successfully",
      "DELETE_FAILURE_ALERT": "Failed to delete Email Configuration"
    }
  },
  "SENDER_CHOOSER": {
    "SENDER": {
      "PLACEHOLDER": "What kind of sender?"
    }
  },
  "SENDER_INFO_RESET": {
    "INSTRUCTION_PROMPT": {
      "PROVIDE_DETAILS": "Provide sender details for emails sent from the platform"
    },
    "INPUT_CHECK": {
      "PROVIDE_SENDER_TYPE": "Select a sender type",
      "PROVIDE_SENDER_ID": "Enter a sender ID"
    },
    "WHAT_IS_SMBLOGIN_DOT_COM": {
      "PLACEHOLDER": "Email Username",
      "TOOLTIP": {
        "TITLE": "What is smblogin.com?",
        "TEXT": "This domain is already authorized to send emails from the platform. Using this domain is an easy way to ensure successful email delivery."
      }
    },
    "REPLY_TO_ADDRESS": {
      "PLACEHOLDER": "Reply-To Address (Optional)",
      "TOOLTIP": {
        "TITLE": "What is Reply-To?",
        "TEXT": "You may provide any email address here. Setting this allows your customers to reply directly to platform emails using an address that you own."
      }
    },
    "UPDATE_BUTTON": {
      "TEXT": "Update sender info"
    },
    "ALERTS": {
      "UPDATE_FAILURE": "Something went wrong. Please try again.",
      "UPDATE_SUCCESS": "Sender info updated."
    }
  },
  "IMAGE_UPLOAD_FAILURE": "Image upload failed",
  "UNSAVED_CHANGES_CONFIRM": "Are you sure you want to leave? You will lose any unsaved changes.",
  "ADD_SOMETHING_HINT": "Add something and see what happens",
  "PLACEHOLDER_OPTIONAL": "Optional",
  "EMAIL_LIBRARY": {
    "PAGE_TITLE": "Emails",
    "PAGE_DESCRIPTION": "Create and customize templates and pre-built content that can be used to build Campaigns.",
    "BROWSER": {
      "NO_RESULTS_PLACEHOLDER": {
        "TITLE": "No results found",
        "SUGGESTION": "Create an email template so it can be reused elsewhere",
        "CREATE_NEW_BUTTON_TEXT": "Create new template"
      },
      "END_OF_RESULTS": "End of search results"
    },
    "FEATURED_TEMPLATES": "Featured templates",
    "MY_TEMPLATES": "My templates",
    "CARD": {
      "CREATED": "Created",
      "UPDATED": "Updated"
    },
    "CREATE_EMAIL_BUTTON_TEXT": "Create email",
    "EMPTY_LIBRARY_PAGE": {
      "TITLE": "Create your first email",
      "DESCRIPTION": "You haven't created any emails yet.",
      "SUGGESTION": "When you create an email, it'll show up here for you to edit, add to automations and campaigns, or send."
    },
    "NO_RESULTS": "No results found",
    "SEARCH_PLACEHOLDER": "Search for an email",
    "TOTAL_RESULTS_PLURAL": "{{ totalResults }} emails",
    "TOTAL_RESULTS_SINGULAR": "{{ totalResults }} email",
    "DUPLICATE": {
      "SUCCESS": "Duplicate {{ duplicateName }} has been created. You may edit and save it.",
      "FAIL": "Failed to duplicate template. Please try again later.",
      "ERROR": {
        "SUBJECT": "An error occurred. Subject is required"
      }
    },
    "DELETE": {
      "CONFIRMATION_MESSAGE": "Deleting this template will permanently remove it.",
      "SUCCESS": "Email template deleted successfully!",
      "FAIL": "Failed to delete template. Please try again later."
    },
    "EDITOR": {
      "TITLE": "Edit template",
      "SAVE_AND_CLOSE": "Save and close",
      "SEND_PREVIEW": "Send preview",
      "ERROR_LOADING_TEMPLATE": "Error loading content"
    }
  },
  "EMAIL_BUILDER": {
    "VALIDATION_ERRORS": {
      "COLOR": "Invalid color. Colors must follow the #XXXXXX format where X is a hexadecimal character.",
      "LINK": "Invalid link. Links must start with 'https://' or 'http://'",
      "DYNAMIC_CONTENT": "Invalid dynamic content. Dynamic content must be in the format {{ dynamicContent }}"
    },
    "MAT_LABELS": {
      "EDIT_EMAIL": "Edit Email",
      "PREVIEW": "Preview",
      "ADVANCED": "Advanced"
    },
    "SUBJECT_CONTROLS_TAB": {
      "TEMPLATE_NAME": "Template name",
      "SUBJECT_LINE": "Subject line",
      "INBOX_PREVIEW": "Inbox preview text",
      "INBOX_PREVIEW_HINT": "Shown beside or below the email title in the inbox"
    },
    "BUTTON_BLOCK": {
      "TEXT": "Button text",
      "STYLE": "Button style",
      "COLOR": "Button color",
      "LINK": "Button link"
    },
    "CONTACT_CARD": {
      "IMAGE": "Image",
      "IMAGE_SIZE": {
        "SIZE": "Size",
        "SMALL": "Small",
        "MEDIUM": "Medium",
        "LARGE": "Large"
      },
      "CLOSE": "Close",
      "CLEAR_IMAGE": "Clear",
      "NAME": "Name",
      "JOB": "Job Title",
      "ADDITIONAL_INFO": "Additional Info",
      "REMOVE_ROW": "Remove Row",
      "ADD_ROW": "Add Row"
    },
    "DIVIDER_BLOCK": {
      "HEIGHT": "Divider Height",
      "COLOR": "Divider Color",
      "COLOR_HINT": "Optional. Defaults to the page background color.",
      "PLACEHOLDER_DEFAULT": "Optional. Defaults to 1."
    },
    "HEADING_BLOCK": {
      "TITLE": "Title",
      "SUBTITLE": "Subtitle",
      "TEXT_ALIGN": "Text Align"
    },
    "HTML_BLOCK": {
      "RAW": "Raw Html"
    },
    "IMAGE_AND_TEXT_BLOCK": {
      "LEARN_MORE": "Learn More",
      "LAYOUT": {
        "TEXT": "Layout",
        "VERTICAL": "Vertical",
        "HORIZONTAL_LARGE": "Horizontal large",
        "HORIZONTAL_MEDIUM": "Horizontal medium",
        "HORIZONTAL_SMALL": "Horizontal small"
      },
      "ADD_NEW_BUTTON_1": "Add a new \"Image and Text\" ",
      "ADD_NEW_BUTTON_2": " using the button below",
      "ADD_ABOVE": "Add new above",
      "DUPLICATE": "Duplicate",
      "COLLAPSE": "Collapse",
      "EXPAND": "Expand",
      "ORDER": "Order",
      "REMOVE": "Remove",
      "MOVE_UP": "Move up",
      "MOVE_TO_TOP": "Move to top",
      "MOVE_DOWN": "Move down",
      "MOVE_TO_BOTTOM": "Move to bottom",
      "IMAGE": "Image",
      "VIEW": "View",
      "HEADING": "Heading",
      "SUBHEADING": "Subheading",
      "BODY_TEXT": "Body Text",
      "LINK": "Link",
      "LINK_TEXT": "Link Text",
      "CLEAR": "Clear",
      "TIPS": {
        "EXPAND": "Expand this item",
        "COLLAPSE": "Collapse this item",
        "REMOVE": "Remove this item"
      }
    },
    "IMAGE_BLOCK": {
      "IMAGE": "Image",
      "CLEAR": "Clear",
      "VIEW": "View",
      "IMAGE_STYLE": {
        "TEXT": "Image Style",
        "FULL_WIDTH": "Full Width",
        "CENTER": "Center",
        "LEFT": "Left",
        "RIGHT": "Right"
      },
      "WIDTH": "Image Width",
      "LINK": "Image Link"
    },
    "RICH_TEXT_BLOCK": {
      "RICH_TEXT": "Rich Text"
    },
    "CONTEXT_SELECTOR": {
      "CONTACT": "Contact",
      "BUSINESS": "Business",
      "X_FOR_PREVIEW": "{{context}} for preview",
      "SELECT_ANOTHER_X": "Select another {{context}}",
      "CONTACT_SELECTOR": {
        "TITLE": "Select contact",
        "SEARCH": "Contact",
        "SEARCH_PLACEHOLDER": "Search for a contact",
        "CANCEL_BUTTON": "Cancel"
      }
    },
    "DYNAMIC_COMPONENT_SELECTOR": {
      "INSERT_DYNAMIC_CONTENT": "Insert dynamic content",
      "SNAPSHOT_REPORT": "Snapshot report",
      "BUSINESS_APP_DETAILS": "Business app details",
      "SALESPERSON_DETAILS": "Salesperson details",
      "PARTNER_DETAILS": "Partner details",
      "ACCOUNT_DETAILS": "Account details",
      "PRODUCT_DETAILS": "Product details",
      "CONTACT_DETAILS": "Contact details",
      "PRIMARY_COMPANY_DETAILS": "Primary company details"
    },
    "DYNAMIC_COMPONENT_DIALOG": {
      "ADD_HEADING": "Add dynamic content",
      "CATEGORY": "Category",
      "CANCEL_BUTTON": "Cancel",
      "ADD_BUTTON": "Add",
      "NO_OPTIONS": "No options found"
    },
    "SIMPLE_TABLE_BLOCK": {
      "TABLE_CONTENT": "Table Content",
      "ADD_ROW": "Add Row",
      "REMOVE_ROW": "Remove Row",
      "LABEL": "Label",
      "VALUE": "Value"
    },
    "SPACER_BLOCK": {
      "HEIGHT": "Spacer Height"
    },
    "REVIEW_REQUEST_LINKS_BLOCK": {
      "REVIEW_REQUEST_SITES": "Review request sites",
      "CONNECT_PAGES": "For best results, <a href='{{ link }}'>connect</a> your Google and Facebook pages.",
      "MODES": {
        "CONFIGURE_MANUALLY": {
          "MAX_SELECTIONS": "Max {{ maxSelections }}"
        }
      },
      "SOURCE_SELECTION_MODAL": {
        "HEADER": "Request reviews on"
      },
      "SOURCES": {
        "GOOGLE": "Google",
        "FACEBOOK": "Facebook"
      }
    },
    "NET_PROMOTER_SCORE_BLOCK": {
      "DISPLAY_AS": "Display as",
      "BUTTON": "Button",
      "INLINE": "Inline",
      "REQUEST_REVIEW": "Request review",
      "YES": "Yes",
      "REQUEST_REVIEW_CONTEXT": "Redirect customers who leave an NPS score higher than 7 to leave review directly on Google"
    },

    "ADVANCED_SETTINGS": {
      "PLACEHOLDER_OPTIONAL_DEFAULT_ANNOUNCEMENT": "Optional. Defaults to Announcement.",
      "COLORS": {
        "COLORS": "Colors",
        "TEXT": "Text",
        "TITLE": "Title",
        "SUBTITLE": "Subtitle",
        "LINKS": "Links",
        "FOOTER_TEXT": "Footer Text",
        "CONTENT_BACKGROUND": "Content Background",
        "PAGE_BACKGROUND": "Page Background"
      },
      "SIZING_AND_SPACING": {
        "SIZING_SPACING": "Sizing and Spacing",
        "TOP_PADDING": {
          "TEXT": "Top Padding",
          "DEFAULT": "Defaults to 32, 0 on Mobile"
        },
        "OUTER_WIDTH": {
          "TEXT": "Outer Width",
          "DEFAULT": "Defaults to 600"
        },
        "INNER_WIDTH": {
          "TEXT": "Inner Width",
          "DEFAULT": "Defaults to 464"
        },
        "INNER_HORIZONTAL_PADDING": {
          "TEXT": "Inner Horizontal Padding",
          "DEFAULT": "Defaults to 25"
        },
        "BOTTOM_CONTENT_PADDING": {
          "TEXT": "Bottom Content Padding",
          "DEFAULT": "Defaults to 48"
        }
      },
      "TOP_LOGO": {
        "TEXT": "Top Logo",
        "DEFAULT": "Default",
        "CUSTOM": "Custom",
        "HIDE": "Hide",
        "CUSTOM_LOGO": "Custom Logo",
        "VIEW": "View",
        "CLEAR": "Clear",
        "LOGO_WIDTH": {
          "TEXT": "Logo Width",
          "DEFAULT": "Defaults to 228"
        },
        "LOGO_ALIGNMENT": {
          "TEXT": "Logo Alignment",
          "CENTER": "Center",
          "LEFT": "Left",
          "RIGHT": "Right"
        },
        "TOP_PADDING": {
          "TEXT": "Top Padding",
          "DEFAULT": "Defaults to 32, 0 on Mobile",
          "BOTTOM_PADDING": {
            "TEXT": "Bottom Padding",
            "DEFAULT": "Defaults to 8"
          }
        },
        "LINK": "Link"
      },
      "FOOTER": {
        "TEXT": "Footer",
        "DISCLAIMER": "Footer Disclaimer"
      },
      "GOOGLE_ANALYTICS": {
        "TEXT": "Google Analytics",
        "NAME": "Campaign Name",
        "SOURCE": "Campaign Source",
        "MEDIUM": "Campaign Medium"
      }
    },
    "BLOCK_PADDING": {
      "TOP_PADDING": "Top Padding",
      "BOTTOM_PADDING": "Bottom Padding"
    },
    "BLOCKS_LIST": {
      "HEADER": "Emails are built with blocks, which can contain text, images, buttons, and more.",
      "ADD_NEW_BLOCK_HEADER": "Add a new block to start composing your email",
      "ADD_NEW": "Add new block",
      "PADDING": "Padding",
      "TOP": "Top",
      "BOTTOM": "Bottom",
      "BETWEEN": "Between",
      "SPACE_BETWEEN_ITEMS": "Space between items",
      "DUPLICATE": "Duplicate",
      "ADD_NEW_ABOVE": "Add New Above",
      "COLLAPSE": "Collapse",
      "COLLAPSE_TIP": "Collapse this block",
      "EXPAND": "Expand",
      "EXPAND_TIP": "Expand this block",
      "ORDER": "Order",
      "DELETE": "Delete",
      "MOVE_TO_TOP": "Move to top",
      "MOVE_UP": "Move up",
      "MOVE_DOWN": "Move down",
      "MOVE_TO_BOTTOM": "Move to bottom",
      "NEW_BADGE": "New"
    },
    "CONTENT_BLOCK_NAMES": {
      "IMAGE": "Image",
      "HEADING": "Heading",
      "RICH_TEXT": "Rich Text",
      "BUTTON": "Button",
      "IMAGE_AND_TEXT": "Image and Text",
      "CONTACT_CARD": "Contact Card",
      "SIMPLE_TABLE": "Simple Table",
      "DIVIDER": "Divider",
      "SPACER": "Spacer",
      "HTML": "Raw Html",
      "REVIEW_REQUEST_LINKS": "Review Request Links",
      "NET_PROMOTER_SCORE": "NPS",
      "SUGGEST_CONTENT": "Suggest Content"
    },
    "CONTENT_SUGGESTION": {
      "PROMPT": "Content Generation",
      "PROMPT_PLACEHOLDER": "Generate a paragraph about your business for a marketing email.",
      "INSTRUCTIONS": "Enter a prompt used to generate content for your email",
      "ERROR_MESSAGE": "An error occurred. Please try again.",
      "GENERATE_CONTENT": "Generate content",
      "GENERATED_CONTENT": "Generated content",
      "GENERATED_CONTENT_PLACEHOLDER": "Are you looking to make your business stand out from the competition? Our innovative solutions can help you do just that! Our team of experts has years of experience in crafting effective strategies that will help you build a solid foundation for success. With our help, you'll be able to maximize your potential and reach new heights. Get in touch with us today to see how we can help your business stand out!",
      "USE_CONTENT": "Use content",
      "INSERT_GENERATED_CONTENT": "Insert AI generated content",
      "CANCEL": "Cancel",
      "GENERATE_IMAGE": "Generate image"
    },
    "SAVING": {
      "GENERIC_ERROR": "An error occurred when trying to save your template, please ensure all required fields are filled out and try again.",
      "TEMPLATE_INVALID_ERROR": "There are invalid entries in your template, please check your template and try again."
    }
  },
  "EMAIL_BUILDER_VINTAGE": {
    "EDIT": "Edit Email",
    "SAVE": "Save",
    "COPY_SOURCE": "Copy Email Source",
    "COPY_JSON": "Copy Email Json",
    "NOT_VISIBLE_TO_RECIPIENTS": "Not visible to your recipients",
    "SUBJECT_LINE": "Email Subject Line",
    "SIMPLE": "Simple",
    "ADVANCED": "Advanced",
    "DYNAMIC_CONTENT": "Dynamic Content",
    "TEMPLATE_EDITOR": {
      "TEMPLATE_LOAD_FAILURE": "Failed to load template",
      "MISSING_BODY_TAGS": "Can not switch editor modes. Missing body tags: ",
      "SWITCH_TO_ADVANCED_FAILURE": "Failed to switch to advanced mode",
      "EMAIL_REQUIRED": "Email addresses are required",
      "SEND_SUCCESS": "Email sent to ",
      "SEND_FAILURE": "Failed to send email to "
    },
    "PREVIOUS_PAGE": "Previous Page"
  },
  "EMAIL_EDITOR_PREVIEW": {
    "DESKTOP": "Desktop",
    "TABLET": "Tablet",
    "MOBILE": "Mobile"
  },
  "EMAIL_USERNAME_INPUT": {
    "EMAIL_REQUIRED": "Sender email is required and cannot contain \"@\"",
    "USE_DIFFERENT_DOMAIN": "Use a different domain"
  },
  "DOMAIN_AUTHORIZATION": {
    "DOMAIN_INFO_CARD": {
      "LEARN_MORE": "Learn more",
      "GENERIC_HELP_PANEL": {
        "TITLE": "General domain authentication instruction",
        "HEADING_1": "Updating your DNS records for email authentication",
        "HEADING_2": "General instructions:",
        "HEADING_3": "Remember:",
        "PAR_1": "Spam laws and regulations are increasing in complexity, and precautions are needed to prevent emails from being flagged as spam. Authenticating your domain ensures your email campaigns pass spam regulations and reach your prospects successfully.",
        "PAR_2": "Before you get started, it’s a good idea to back up your existing DNS settings. Take a screenshot or download them in case you need to revert back to your original settings.",
        "PAR_3": "While specific instructions may vary slightly depending on your provider, these general steps will help you navigate the process:",
        "PAR_4": "By following these clear steps and referring to your email provider's specific instructions, you'll successfully update your DNS records for email authentication and ensure your business emails reach their intended recipients!",
        "BULLET_LIST": {
          "ITEM_1": "Double-check your nameservers: Make sure they point to your current DNS provider. If unsure, your IT department or the provider's support can assist.",
          "ITEM_2": "Need help? Don't hesitate to reach out to your DNS provider's support team. They're there to guide you through the process.",
          "ITEM_3": "These are general guidelines. Consult your specific email service provider's support articles for detailed instructions tailored to their platform.",
          "ITEM_4": "Field names may vary. Don't worry if you see slight differences in field names; the overall steps remain similar.",
          "ITEM_5": "Take your time and be careful. Double-check entries to avoid errors that could impact email delivery."
        },
        "NUMBERED_LIST": {
          "ITEM_1": "Log in to your DNS provider's account: Familiar platforms like GoDaddy or Namecheap often make this easy.",
          "ITEM_2": "Find the CNAME record: Look for the existing CNAME record for your chosen subdomain or create a new one. Field names like \"host,\" \"name,\" or \"alias\" might describe the same field depending on your provider.",
          "ITEM_3": "Fill in the details: In the appropriate field (host, name, alias), enter the prefix for your subdomain (e.g., \"mail\").",
          "ITEM_4": "Paste the CNAME value: In the \"target\" or \"points to\" field, paste the CNAME record provided by your email service provider. It typically looks similar to: ___.domainkey.______.sendgrid.net.",
          "ITEM_5": "Redirect your non-www domain (optional): If your provider allows forwarding, set up a redirect in your DNS settings. This ensures your non-www domain (e.g., yourdomain.com) forwards to your www domain (e.g., www.yourdomain.com)."
        },
        "CLOSE": "Close"
      },
      "TRY_AGAIN": "Try again",
      "USE_AS_DEFAULT_DOMAIN": "Use as default domain",
      "ACTIVE": "Active",
      "GENERIC_VALIDATION": {
        "SUCCESS": "All records match. This domain is ready to use.",
        "FAILURE": "Some domain records are missing or incorrect.",
        "ERROR": "Unable to check domain records. Try again."
      },
      "STATUS_BANNER": {
        "ERROR": "There was a problem validating this domain"
      },
      "DNS_RECORDS": {
        "HEADER": "DNS Records",
        "SUBTEXT": "Changes to DNS records may take up to 72 hours to update",
        "HELP_MESSAGE": "Need help updating your records?",
        "HELP_BUTTON": "View help article"
      },
      "DMARC_RECORDS": {
        "HEADER": "DMARC Records",
        "DESCRIPTION": "DMARC records control what happens to emails that fail SPF and DKIM checks. Make sure to set up an email address to receive DMARC reports or change the address used in the record to one you can access"
      },
      "SPF_RECORDS": {
        "HEADER": "SPF Records"
      },
      "DKIM_RECORDS": {
        "HEADER": "DKIM Records"
      },
      "A_RECORDS": {
        "HEADER": "A Records"
      },
      "CNAME_RECORDS": {
        "HEADER": "CNAME Records"
      },
      "ACTIONS": {
        "CHECK_RECORDS": "Check domain records"
      },
      "ERROR_NOTICE": {
        "HEADER": "Domain authorization required",
        "HINT": "Install records on your domain to use it in your sender address"
      }
    },
    "DOMAIN_RECORDS_TABLE": {
      "TYPE": "Record type",
      "HOST": "Host (Name)",
      "VALUE": "Value",
      "FOUND": "Current value",
      "NOT_FOUND": "Not found",
      "NOT_FOUND_LONG": "We could not find this record on your domain. DNS propagation can take up to 72 hours.",
      "MISMATCH_LONG": "We found a record on your domain. But the highlighted value is incorrect.",
      "COPY_SUCCESS": "Copied to clipboard",
      "CLICK_TO_COPY": "Click to copy"
    },
    "DOMAINS": {
      "AUTH_DOMAIN_BUTTON": "Authorize a domain",
      "NEW_DOMAIN": "Authorize a domain to start using it for emails",
      "ADDITIONAL_DOMAIN": "Authorize another domain",
      "NO_DOMAINS": "No usable domains"
    },
    "DOMAINS_DIALOG": {
      "HEADER": "Select a domain for your email sender address",
      "HINT": "You can only use a domain if the platform has been authorized to use that domain.",
      "INFO": "More Info",
      "ERROR": "Something went wrong"
    },
    "NEW_DOMAIN_DIALOG": {
      "ADD_DOMAIN": "Add domain",
      "AUTH_ERROR": "There was a problem. Try again.",
      "STARTED": "Domain authorization started.",
      "INVALID_ERROR": "Some of the provided form values are not valid",
      "NOT_PROVIDED": "ERROR: Dialog input data was not provided",
      "TITLE": "Add email domain",
      "HEADER": "Enter a domain to use for sending emails",
      "START": "Authorize domain",
      "CANCEL": "Cancel"
    },
    "SENDER_INFO_FORM": {
      "TITLE": "Advanced email settings",
      "NAME": "Sender name",
      "NAME_REQUIRED": "Sender name is required",
      "SENDER_DOMAIN": "Sender domain",
      "USE_DEFAULT": "Use default authenticated email domain",
      "USE_OWN": "Use my own email domain",
      "EMPTY_DOMAIN": "You must first choose an authorized domain",
      "CHOOSE_DOMAIN": "Choose a domain to use",
      "ADD_DOMAIN": "Add domain",
      "REPLY_ADDRESS": "Reply Address",
      "REPLY_ADDRESS_NOT_VALID": "Reply address is not valid",
      "FORWARDING_ADDRESS": "Forwarding address",
      "FORWARDING_ADDRESS_DESCRIPTION": "Set up email forwarding to Inbox on an external email account.",
      "LEARN_MORE": "Learn more",
      "FORWARDING_ADDRESS_INFO": "<b>Forwarding into Inbox from other email addresses</b><p>By setting up email forwarding in your email client, you can receive emails from leads and customers in your centralized Inbox, so your team can collaborate on replying.</p><p>For example, you might use a general business email address for customer inquiries, like team@yourcompany.com. With forwarding, any time someone sends an email to that address, it will also appear in Inbox.</p><p><b>WARNING:</b> If you wish to forward emails from an address that's associated with a user of your account, make sure you either a) filter out app notification emails from forwarding, or b) turn off Inbox email notifications for that user, otherwise you will experience a noisy email feedback loop as Inbox notification emails appear back inside Inbox.</p><b>How to set up forwarding from Gmail:</b><ol> <li>In gmail.com, go to Settings > See all settings.</li> <li>Select <a href='https://mail.google.com/mail/u/0/#settings/fwdandpop'>Forwarding and POP/IMAP</a> tab</li> <li>Click “Add a forwarding address” and paste in your unique email address from Inbox Settings, click Next, sign in again if required, and confirm.</li> <li>In Inbox, you will receive a confirmation message. Click the link to confirm the request to forward email.</li> <li>After confirming, you will need to return to Gmail Forwarding settings one more time, to ENABLE forwarding, then save your changes. You can also optionally use filtering to forward only some of the messages.</li></ol><b>How to set up forwarding from Outlook:</b><ol> <li>In outlook.com, select Settings</li> <li>Select Mail > <a href='https://outlook.live.com/mail/options/mail/forwarding'>Forwarding.</a></li> <li>To turn on forwarding, select Enable forwarding, enter the forwarding email address, and select Save.</li></ol>",
      "FORWARDING_ADDRESS_INFO_TITLE": "Understanding Forwarding Email Addresses",
      "CLOSE_BUTTON": "Close",
      "CONFIGURATION_ALERT_MESSAGE": "Sender name and reply address are used by Campaigns and other integrated services. Emails sent via inbox will not be affected by these settings."
    },
    "SENDER_INFO_CARD": {
      "INPUT_NOT_PROVIDED": "ERROR: SenderInfoCard input data was not provided",
      "GENERAL": "Sender and reply settings",
      "SAVE": "Save",
      "LOADING_ERROR": "There was a problem loading sender info",
      "TRY_AGAIN": "Try Again",
      "UPDATE_SUCCESS": "Updated sender info successfully",
      "UPDATE_FAILURE": "There was a problem updating sender info",
      "DOMAIN_SELECT_SUCCESS": "Domain selected. Review sender info before saving changes."
    },
    "GENERAL": {
      "HEADER": "My Email domains",
      "PURPOSE": "An email domain's DNS records (SPF, DKIM, and DMARC) help with deliverability, ensuring your emails pass spam filters and reach your recipients successfully.",
      "ERROR": "Something went wrong"
    }
  },
  "EMAIL_STATS": {
    "EMAIL_PERFORMANCE": "Email Performance",
    "DELIVERY_STATUS": "Delivery Status",
    "LINK_ACTIVITY": "Link Activity",
    "TOTAL_CLICKS": "Total Clicks",
    "UNIQUE_CLICKS": "Unique Clicks",
    "SEE_WHO_CLICKED": "See contacts who clicked",
    "CLICK_RATIO": "Click Ratio",
    "LINK_CLICK_RATE_TOOLTIP": "Percent of email recipients that clicked on this link",
    "OPEN_RATE": "Opens",
    "OPEN_RATE_TOOLTIP_SINGLE": "1 of the delivered emails was opened.",
    "OPEN_RATE_TOOLTIP": "{{ opened }} of the delivered emails were opened",
    "CTOR": "CTOR",
    "CLICK_RATE_TOOLTIP_SINGLE": "Click-to-open rate. 1 of the opened emails was clicked through.",
    "CLICK_RATE_TOOLTIP": "Click-to-open rate. {{ clickedThrough }} of the opened emails were clicked through.",
    "OPENED": "Opened",
    "OPENED_TOOLTIP_SINGLE": "1 email was opened by recipients on this campaign.",
    "OPENED_TOOLTIP": "{{ opened }} emails were opened by recipients on this campaign.",
    "CLICKED": "Clicks",
    "CLICKED_TOOLTIP_SINGLE": "Links in this email were clicked 1 time.",
    "CLICKED_TOOLTIP": "Links in this email were clicked {{ clicked }} times.",
    "DELIVERED": "Delivered",
    "DELIVERED_TOOLTIP_SINGLE": "1 email has been delivered.",
    "DELIVERED_TOOLTIP": "{{ delivered }} emails have been delivered.",
    "BOUNCED": "Bounced",
    "BOUNCED_TOOLTIP_SINGLE": "1 email failed to deliver. This is often caused by an incorrect email address.",
    "BOUNCED_TOOLTIP": "{{ bounced }} emails failed to deliver. This is often caused by an incorrect email address.",
    "UNSUBSCRIBED": "Unsubscribed",
    "UNSUBSCRIBED_TOOLTIP_SINGLE": "1 recipient unsubscribed from this email.",
    "UNSUBSCRIBED_TOOLTIP": "{{ unsubscribed }} recipients unsubscribed from this email.",
    "SPAM": "Spam",
    "SPAM_REPORT_TOOLTIP_SINGLE": "1 recipient marked this email as spam.",
    "SPAM_REPORT_TOOLTIP": "{{ spamReport }} recipients marked this email as spam.",
    "DROPPED": "Dropped",
    "DROPPED_TOOLTIP_SINGLE": "1 email can’t be delivered. This occurs when a recipient has unsubscribed from a previous email in your campaign or marked a previous email as spam.",
    "DROPPED_TOOLTIP": "{{ dropped }} emails can’t be delivered. This occurs when a recipient has unsubscribed from a previous email in your campaign or marked a previous email as spam."
  },
  "CONTACT_TABLE": {
    "TABLE_HEADERS": {
      "NAME": "Name",
      "RECIPIENT_EMAIL": " Recipient Email",
      "CLICKS": "Clicks",
      "DATE_CLICKED": "Date Clicked"
    },
    "EMPTY_STATE": {
      "DESCRIPTION": "There is no link activity to display"
    },
    "DOWNLOAD_DIALOG": {
      "TITLE": "Download link activity",
      "DESCRIPTION": "This action will download the data that's been filtered on your screen.",
      "NOTIFICATION": "You'll receive a notification when the file is ready to download. You can access the file by clicking that notification.",
      "DOWNLOAD": "Download",
      "SUCCESS": "Generating CSV file. You will be notified when it is ready. \uD83D\uDD14",
      "ERROR": "There was a problem downloading data. Please try again."
    }
  },
  "EMAIL_ACTIVITY": {
    "TITLE": "Email Activity",
    "EVENT_TYPE": "Event type",
    "EVENT_TIME": "Event time",
    "NO_EVENTS": "There are no events for this attribute."
  }
}
