<glxy-page>
  <glxy-page-toolbar>
    <glxy-page-title>Email Management</glxy-page-title>
  </glxy-page-toolbar>
  <glxy-page-wrapper maxWidth="440">
    <glxy-alert style="margin-bottom: 16px" type="error">
      <strong>Email Master Control</strong>

      <glxy-alert-extended>
        <div class="toggle-wrapper">
          <mat-slide-toggle
            (change)="setStatus($event)"
            [checked]="queueStatus$ | async"
            [disabled]="(queueStatus$ | async) === null"
          >
            Email Queues Running
          </mat-slide-toggle>
        </div>

        <mat-divider />

        <mat-nav-list>
          <p mat-subheader>This toggle controls processing of the following queues:</p>

          <a
            mat-list-item
            href="https://console.cloud.google.com/cloudtasks/queue/us-central1/ns-notifications/tasks?project=vbc-{{
              env
            }}"
            target="_blank"
            rel="”noopener”"
          >
            <mat-icon matListItemIcon>notifications_none</mat-icon>
            <span matListItemLine>ns-notifcations</span>
            <mat-icon class="fade">open_in_new</mat-icon>
          </a>

          <a
            mat-list-item
            href="https://console.cloud.google.com/cloudtasks/queue/us-central1/sendgrid-email/tasks?project=vbc-{{
              env
            }}"
            target="_blank"
            rel="”noopener”"
          >
            <mat-icon matListItemIcon>mail_outline</mat-icon>
            <span matListItemLine>sendgrid-email</span>
            <mat-icon class="fade">open_in_new</mat-icon>
          </a>

          <a
            mat-list-item
            href="https://console.cloud.google.com/cloudtasks/queue/us-central1/email-send-push-{{
              env
            }}/tasks?project=repcore-prod"
            target="_blank"
            rel="”noopener”"
          >
            <mat-icon matListItemIcon>keyboard_tab</mat-icon>
            <span matListItemLine>email-send-push</span>
            <mat-icon class="fade">open_in_new</mat-icon>
          </a>
        </mat-nav-list>
      </glxy-alert-extended>
    </glxy-alert>
  </glxy-page-wrapper>
</glxy-page>
