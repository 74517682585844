import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SenderType } from '@vendasta/email';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, merge } from 'rxjs';
import { SubscriptionList } from '@vendasta/rx-utils';

export interface FormPayload {
  senderType: SenderType;
  senderId: string;
}

@Component({
  selector: 'email-sender-chooser',
  templateUrl: './sender-chooser.component.html',
  styleUrls: ['./sender-chooser.component.scss'],
})
export class SenderChooserComponent implements OnInit, OnDestroy {
  @Input()
  set initialSelection(v: FormPayload) {
    if (!v) {
      return;
    }
    this.initialSelection$$.next(v);
  }

  private initialSelection$$ = new BehaviorSubject<FormPayload>({
    senderType: SenderType.SENDER_TYPE_PARTNER,
    senderId: '',
  });

  @Output()
  public readonly selection = new EventEmitter<FormPayload>();

  private readonly subs = SubscriptionList.new();

  readonly partnerSenderType = SenderType.SENDER_TYPE_PARTNER;
  readonly businessSenderType = SenderType.SENDER_TYPE_BUSINESS;

  readonly searchForm = new UntypedFormGroup({
    senderType: new UntypedFormControl(SenderType.SENDER_TYPE_PARTNER, [Validators.required]),
    senderId: new UntypedFormControl('', [Validators.required]),
  });

  private readonly valueChanges$ = merge(
    this.initialSelection$$,
    this.searchForm.valueChanges.pipe(distinctUntilChanged(), shareReplay(1)),
  );

  readonly idLabel$ = this.valueChanges$.pipe(
    filter((v) => !!v),
    map((v: FormPayload) => {
      if (v.senderType === SenderType.SENDER_TYPE_PARTNER) {
        return 'Partner ID'; // Not translating to preserve integrity with data model
      }
      if (v.senderType === SenderType.SENDER_TYPE_BUSINESS) {
        return 'Business ID'; // Not translating to preserve integrity with data model
      }
      return 'Sender ID'; // Not translating to preserve integrity with data model
    }),
  );

  ngOnDestroy(): void {
    this.subs.destroy();
  }

  ngOnInit(): void {
    this.subs.add(this.initialSelection$$, (f: FormPayload) => {
      this.searchForm.setValue(f);
    });
    this.subs.add(this.valueChanges$, (f: FormPayload) => {
      this.selection.emit(f);
    });
  }
}
