import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Sender } from '@vendasta/email';
import { firstValueFrom, Observable } from 'rxjs';
import {
  Result as DialogResult,
  DomainsDialogComponent,
} from '../domain-authorization/src/domains-dialog/domains-dialog.component';
import { DefaultDomainService } from '../domain-authorization/src/default-domain.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'email-username-input',
  templateUrl: './email-username-input.component.html',
  styleUrls: ['./email-username-input.component.scss'],
})
export class EmailUsernameInputComponent implements OnInit {
  public isDefaultDomain$: Observable<boolean>;

  @Output() readonly authorizeRequested = new EventEmitter<null>();
  @Output() readonly domainChangeRequested = new EventEmitter<string>();
  @Output() readonly invalidDomainChangeRequested = new EventEmitter<string>();

  @Input() readonly form: UntypedFormGroup;
  @Input() readonly formControlNameString: string;
  @Input() readonly sender: Sender;
  @Input() readonly domain$: Observable<string>;

  constructor(
    private readonly dialog: MatDialog,
    private defaultDomainService: DefaultDomainService,
  ) {}

  ngOnInit(): void {
    this.isDefaultDomain$ = this.domain$.pipe(
      switchMap((domain) => this.defaultDomainService.isDefaultDomain$(domain, this.sender.type)),
    );
  }

  async changeDomain(): Promise<void> {
    const dialog = this.dialog.open(DomainsDialogComponent, {
      data: this.sender,
    });
    const result: DialogResult = await firstValueFrom(dialog.afterClosed());
    switch (result.choiceType) {
      case 'new':
        this.authorizeRequested.emit();
        break;
      case 'valid':
        this.domainChangeRequested.emit(result.newDomain);
        break;
      case 'invalid':
        this.invalidDomainChangeRequested.emit(result.newDomain);
        break;
    }
  }
}
