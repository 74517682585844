import { NgModule } from '@angular/core';
import { AsyncUiModule, FilterService, VaFilterModule } from '@vendasta/uikit';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { GalaxyPageModule } from '@vendasta/galaxy/page';
import { GalaxyAlertModule } from '@vendasta/galaxy/alert';
import { EmailModule as EmailSDKModule } from '@vendasta/email';

import { EmailPreviewDialogComponent } from './preview/email-preview.component';
import { EmailAdminComponent } from './admin/email-admin.component';
import { EmailComponent } from './email.component';
import { RoutingModule } from './email.routing';
import { SendgridAdminsModule } from './sendgrid-admins/sendgrid-admins.module';
import { CommonModule } from '@angular/common';
import { GalaxyEmptyStateModule } from '@vendasta/galaxy/empty-state';
import { CopyToClipboardDirective } from '../../shared/directives/copy-to-clipboard.directive';
import { TranslateModule } from '@ngx-translate/core';
import { EmailUsersModule } from './email-users/email-users.module';
import { EmailSendersModule } from './email-senders/email-senders.module';
import { GalaxySnackbarModule } from '@vendasta/galaxy/snackbar-service';
import { GalaxyPipesModule } from '@vendasta/galaxy/pipes';

@NgModule({
  declarations: [EmailComponent, EmailAdminComponent, EmailPreviewDialogComponent, CopyToClipboardDirective],
  imports: [
    RoutingModule,
    MatTableModule,
    MatCardModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    VaFilterModule,
    MatListModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatTooltipModule,
    MatSlideToggleModule,
    GalaxyPageModule,
    GalaxyAlertModule,
    GalaxyEmptyStateModule,
    MatProgressSpinnerModule,
    GalaxySnackbarModule,
    SendgridAdminsModule,
    AsyncUiModule,
    CommonModule,
    MatIconModule,
    TranslateModule,
    EmailSDKModule,
    EmailUsersModule,
    EmailSendersModule,
    GalaxyPipesModule,
  ],
  providers: [FilterService],
  exports: [],
})
export class EmailModule {}
