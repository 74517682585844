import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendgridAdminsSource } from './email/sendgrid-admins-source.service';
import { SendgridReputationsSource } from './email/sendgrid-reputations.source';
import {
  DataSourcesModule,
  EmailCategoriesSource,
  EmailPreferencesSource,
} from '@galaxy/email-ui/preferences/data-sources';

@NgModule({
  providers: [SendgridAdminsSource, SendgridReputationsSource, EmailCategoriesSource, EmailPreferencesSource],
  declarations: [],
  imports: [CommonModule, DataSourcesModule],
})
export class DataProvidersModule {}
