import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailComponent } from './email.component';
import { EmailAdminComponent } from './admin/email-admin.component';
import { SendgridAdminsComponent } from './sendgrid-admins/sendgrid-admins/sendgrid-admins.component';
import { SendGridIndexComponent } from './send-grid-index/send-grid-index.component';
import { SendGridReputationComponent } from './send-grid-reputation/send-grid-reputation.component';
import { EmailUsersComponent } from './email-users/email-users.component';
import { EmailSendersComponent } from './email-senders/email-senders.component';
import { PendingChangesGuard } from '../../core/pending-changes.guard';

const routes: Routes = [
  {
    path: '',
    component: EmailComponent,
  },
  {
    path: 'message/:emailId',
    component: EmailComponent,
  },
  {
    path: 'admin',
    component: EmailAdminComponent,
  },
  {
    path: 'sendgrid',
    component: SendGridIndexComponent,
  },
  {
    path: 'sendgrid/admins',
    component: SendgridAdminsComponent,
  },
  {
    path: 'sendgrid/reputation',
    component: SendGridReputationComponent,
  },
  {
    path: 'users',
    component: EmailUsersComponent,
  },
  {
    path: 'senders',
    component: EmailSendersComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'senders/:senderType',
    component: EmailSendersComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'senders/:senderType/:senderId',
    component: EmailSendersComponent,
    canDeactivate: [PendingChangesGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RoutingModule {}
