import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreferencesComponent } from './preferences.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AsyncUiModule } from '@vendasta/uikit';
import { GalaxyWrapModule } from '@vendasta/galaxy/galaxy-wrap';
import { TruncatePipe } from './truncate.pipe';
import { GalaxyTooltipModule } from '@vendasta/galaxy/tooltip';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [TruncatePipe, PreferencesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    AsyncUiModule,
    GalaxyWrapModule,
    GalaxyTooltipModule,
  ],
  exports: [PreferencesComponent],
})
export class PreferencesModule {}
