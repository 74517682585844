// TODO: This is duplicated from PCC.  Consider adding to galaxy or core.
import { HostListener, Injectable, Directive } from '@angular/core';

import { Observable } from 'rxjs';

export function displayPendingChangesDialog(): boolean {
  const message =
    'You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.';
  return confirm(message);
}

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class ComponentCanDeactivate {
  abstract canDeactivate(): boolean | Observable<boolean>;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }

  // If you want your own dialog, you could override this method
  pendingChangesDialog(): boolean | Observable<boolean> {
    return displayPendingChangesDialog();
  }
}

@Injectable()
export class PendingChangesGuard {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    if (component.canDeactivate()) {
      return true;
    }
    return component.pendingChangesDialog();
  }
}
