import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomainRecordsTableComponent, ToTableRowClassPipe } from './domain-records-table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { GalaxyTooltipModule } from '@vendasta/galaxy/tooltip';
import { EmailUII18nModule } from '../../../src/lib/i18n/email-ui-i18n.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DomainRecordsTableComponent, ToTableRowClassPipe],
  imports: [CommonModule, MatButtonModule, MatIconModule, GalaxyTooltipModule, EmailUII18nModule, TranslateModule],
  exports: [DomainRecordsTableComponent],
  providers: [ToTableRowClassPipe],
})
export class DomainRecordsTableModule {}
