<ng-container *ngIf="isContainerLarge$ | async">
  <table class="large-screen">
    <thead>
      <td>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.TYPE' | translate }}</td>
      <td>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.HOST' | translate }}</td>
      <td>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.VALUE' | translate }}</td>
      <td *ngIf="showMismatches">{{ 'DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.FOUND' | translate }}</td>
    </thead>
    <ng-container *ngFor="let row of expectedRecords">
      <tr>
        <ng-container [ngTemplateOutlet]="td" [ngTemplateOutletContext]="{ cellText: row.recordType }"></ng-container>
        <ng-container [ngTemplateOutlet]="td" [ngTemplateOutletContext]="{ cellText: row.recordHost }"></ng-container>
        <ng-container [ngTemplateOutlet]="td" [ngTemplateOutletContext]="{ cellText: row.recordValue }"></ng-container>
        <td *ngIf="showMismatches">
          <ng-container [ngTemplateOutlet]="highlight" [ngTemplateOutletContext]="{ row: row }"></ng-container>
        </td>
      </tr>
    </ng-container>
  </table>
</ng-container>

<ng-container *ngIf="(isContainerLarge$ | async) === false">
  <ng-container *ngFor="let row of expectedRecords">
    <table class="small-screen">
      <tr>
        <td>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.TYPE' | translate }}</td>
        <td>{{ row.recordType }}</td>
      </tr>
      <tr>
        <td>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.HOST' | translate }}</td>
        <ng-container [ngTemplateOutlet]="td" [ngTemplateOutletContext]="{ cellText: row.recordHost }"></ng-container>
      </tr>
      <tr>
        <td>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.VALUE' | translate }}</td>
        <ng-container [ngTemplateOutlet]="td" [ngTemplateOutletContext]="{ cellText: row.recordValue }"></ng-container>
      </tr>
      <tr *ngIf="!showMismatches" [class]="row | toTableRowClass">
        <td>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.FOUND' | translate }}</td>
        <td>
          <ng-container [ngTemplateOutlet]="highlight" [ngTemplateOutletContext]="{ row: row }"></ng-container>
        </td>
      </tr>
    </table>
  </ng-container>
</ng-container>

<ng-template #td let-cellText="cellText">
  <td
    class="copy-cell"
    [glxyTooltip]="'DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.CLICK_TO_COPY' | translate"
    (click)="copyValue(cellText)"
  >
    <span>{{ cellText }}</span>
  </td>
</ng-template>

<ng-template #highlight let-row="row">
  <ng-container *ngIf="row.recordValueMatchPrefix || row.recordValueMatchSuffix">
    <span>{{ row.recordValueMatchPrefix }}</span>
    <span class="mismatch" [glxyTooltip]="'DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.MISMATCH_LONG' | translate">
      {{ row.recordValueMismatchPortion }}
    </span>
    <span>{{ row.recordValueMatchSuffix }}</span>
  </ng-container>
  <ng-container *ngIf="!row.recordValueMatchPrefix && !row.recordValueMatchSuffix">
    <span class="mismatch" [glxyTooltip]="'DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.NOT_FOUND_LONG' | translate">
      {{ 'DOMAIN_AUTHORIZATION.DOMAIN_RECORDS_TABLE.NOT_FOUND' | translate }}
    </span>
  </ng-container>
</ng-template>
