import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailSendersComponent } from './email-senders.component';
import { GalaxyPageModule } from '@vendasta/galaxy/page';
import { MatCardModule } from '@angular/material/card';
import { GalaxyAlertModule } from '@vendasta/galaxy/alert';
import { MatTabsModule } from '@angular/material/tabs';
import { DomainRecordsModule } from './domain-records/domain-records.module';
import { PendingChangesGuard } from '../../../core/pending-changes.guard';

@NgModule({
  declarations: [EmailSendersComponent],
  imports: [CommonModule, GalaxyPageModule, GalaxyAlertModule, MatCardModule, MatTabsModule, DomainRecordsModule],
  providers: [PendingChangesGuard],
})
export class EmailSendersModule {}
