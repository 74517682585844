<ng-container *ngIf="domainResult$ | glxyAsyncStatus | async as result">
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header>
      <!-- Icon -->
      <ng-container [ngSwitch]="result.status">
        <ng-container *ngSwitchCase="'loading'">
          <glxy-loading-spinner [size]="'small'" [fullWidth]="false"></glxy-loading-spinner>
        </ng-container>
        <ng-container *ngSwitchCase="'error'">
          <mat-icon>warning</mat-icon>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <email-domain-status-icon [result]="result.value"></email-domain-status-icon>
        </ng-container>
      </ng-container>

      <!-- Text -->
      <span>{{ domain.sendFromDomain }}</span>
      <ng-container *ngIf="domain.isPrimary">
        <span>({{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.ACTIVE' | translate }})</span>
      </ng-container>
    </mat-expansion-panel-header>

    <div class="domain-info">
      <!-- Status banner -->
      <ng-container [ngSwitch]="result.status">
        <ng-container *ngSwitchCase="'error'">
          <glxy-alert type="warning" [showAction]="true" [actionTitle]="'Try Again'" (actionClick)="runValidation()">
            {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.STATUS_BANNER.ERROR' | translate }}
          </glxy-alert>
        </ng-container>
        <ng-container *ngSwitchCase="'loading'">
          <!-- Nothing -->
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="!result?.value?.allRecordsMatch" [ngTemplateOutlet]="errorNotice"></ng-container>
        </ng-container>
      </ng-container>

      <h3>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.DNS_RECORDS.HEADER' | translate }}</h3>

      <p>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.DNS_RECORDS.SUBTEXT' | translate }}</p>

      <h4>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.DMARC_RECORDS.HEADER' | translate }}</h4>

      <email-domain-records-table
        [expectedRecords]="[targetDmarc]"
        [mismatchedRecords]="result.value?.mismatches"
        [showMismatches]="true"
      ></email-domain-records-table>

      <ng-container *ngIf="targetA">
        <h4>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.A_RECORDS.HEADER' | translate }}</h4>
        <email-domain-records-table
          [expectedRecords]="[targetA]"
          [mismatchedRecords]="result.value?.mismatches"
          [showMismatches]="true"
        ></email-domain-records-table>
      </ng-container>

      <ng-container *ngIf="targetCNAME">
        <h4>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.CNAME_RECORDS.HEADER' | translate }}</h4>
        <email-domain-records-table
          [expectedRecords]="[targetCNAME]"
          [mismatchedRecords]="result.value?.mismatches"
          [showMismatches]="true"
        ></email-domain-records-table>
      </ng-container>

      <h4>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.SPF_RECORDS.HEADER' | translate }}</h4>

      <email-domain-records-table
        [expectedRecords]="[targetSpf]"
        [mismatchedRecords]="result.value?.mismatches"
        [showMismatches]="true"
      ></email-domain-records-table>

      <h4>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.DKIM_RECORDS.HEADER' | translate }}</h4>

      <email-domain-records-table
        [expectedRecords]="targetDKIMs"
        [mismatchedRecords]="result.value?.mismatches"
        [showMismatches]="true"
      ></email-domain-records-table>
    </div>

    <div class="actions-container">
      <div class="actions" [ngClass]="{ shadow: showShadow }">
        <ng-container *ngIf="result.status === 'loaded' && !result.value?.mismatches">
          <button
            mat-raised-button
            color="primary"
            [disabled]="result?.status === 'loading'"
            (click)="useAsDefaultRequested.emit(this.domain.sendFromDomain)"
          >
            <glxy-button-loading-indicator>
              {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.USE_AS_DEFAULT_DOMAIN' | translate }}
            </glxy-button-loading-indicator>
          </button>
          <button mat-stroked-button (click)="runValidation()" [disabled]="result?.status === 'loading'">
            <glxy-button-loading-indicator [isLoading]="result?.status === 'loading'">
              {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.ACTIONS.CHECK_RECORDS' | translate }}
            </glxy-button-loading-indicator>
          </button>
        </ng-container>
        <ng-container *ngIf="result?.status === 'loading' || !!result.value?.mismatches">
          <button mat-raised-button color="primary" (click)="runValidation()" [disabled]="result?.status === 'loading'">
            <glxy-button-loading-indicator [isLoading]="result?.status === 'loading'">
              {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.ACTIONS.CHECK_RECORDS' | translate }}
            </glxy-button-loading-indicator>
          </button>
        </ng-container>

        <ng-container *ngIf="showDeleteButton && !domain.isPrimary">
          <button mat-stroked-button (click)="deleteRequested.emit(domain.sendFromDomain)">
            <glxy-button-loading-indicator [isLoading]="deleteInProgress">Delete</glxy-button-loading-indicator>
          </button>
        </ng-container>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>

<ng-template #errorNotice>
  <glxy-alert
    type="error"
    class="authError"
    [showAction]="true"
    [actionTitle]="'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.LEARN_MORE' | translate"
    (actionClick)="openHelpArticle()"
  >
    <div>
      <em>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.ERROR_NOTICE.HEADER' | translate }}</em>
      - {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.ERROR_NOTICE.HINT' | translate }}
    </div>
  </glxy-alert>
</ng-template>

<ng-template #genericHelpModal>
  <h2 mat-dialog-title>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.TITLE' | translate }}</h2>

  <mat-dialog-content>
    <p>
      {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.PAR_1' | translate }}
    </p>
    <h4>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.HEADING_1' | translate }}</h4>
    <p>
      {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.PAR_2' | translate }}
    </p>
    <ul>
      <li>
        {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.BULLET_LIST.ITEM_1' | translate }}
      </li>
      <li>
        {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.BULLET_LIST.ITEM_2' | translate }}
      </li>
    </ul>
    <h4>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.HEADING_2' | translate }}</h4>
    <p>
      {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.PAR_3' | translate }}
    </p>
    <ol>
      <li>
        {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.NUMBERED_LIST.ITEM_1' | translate }}
      </li>
      <li>
        {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.NUMBERED_LIST.ITEM_2' | translate }}
      </li>

      <li>
        {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.NUMBERED_LIST.ITEM_3' | translate }}
      </li>
      <li>
        {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.NUMBERED_LIST.ITEM_4' | translate }}
      </li>
      <li>
        {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.NUMBERED_LIST.ITEM_5' | translate }}
      </li>
    </ol>
    <h4>{{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.HEADING_1' | translate }}</h4>
    <ul>
      <li>
        {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.BULLET_LIST.ITEM_3' | translate }}
      </li>
      <li>
        {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.BULLET_LIST.ITEM_4' | translate }}
      </li>
      <li>
        {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.BULLET_LIST.ITEM_5' | translate }}
      </li>
    </ul>
    <p>
      {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.PAR_4' | translate }}
    </p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button color="secondary" matDialogClose>
      {{ 'DOMAIN_AUTHORIZATION.DOMAIN_INFO_CARD.GENERIC_HELP_PANEL.CLOSE' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
