export const devPyMappings: { [regex: string]: string } = {
  '.*s~vbc-(?:demo|prod)\\/marketing-automation:.*\\.(\\d+)(.*)':
    'https://github.com/vendasta/marketing-automation/blob/master/src',
  's~vbc-(?:demo|prod).*?.*\\.(\\d+)(.*)': 'https://github.com/vendasta/VBC/blob/master/src',
  's~developers-vendasta-(?:demo|prod).*?.*\\.(\\d+)(.*)':
    'https://github.com/vendasta/developer-central/blob/master/src',
  's~website-pro-(?:demo|prod).*?.*\\.(\\d+)(.*)': 'https://github.com/vendasta/wsp-portal/blob/master/src',
};

export const devGoMappings: { [uid: string]: string } = {
  // Demo
  'U-4dc51001-43a0-4637-86f0-8a1d7c0921e4': 'https://github.com/vendasta/sales-orders/blob/master',
  'U-ed5a9a1e-d336-4d3b-860d-75946abd9c27': 'https://github.com/vendasta/developer-central/blob/master',
  'U-f78c792b-2456-4493-996a-4ea29cc0503f': 'https://github.com/vendasta/business-center/blob/master',
  'U-fd604765-fa0e-48cd-812d-a20ac3f61176': 'https://github.com/vendasta/billing/blob/master',
  'U-1dd8630c-1d42-40d1-8596-b3f84d317723': 'https://github.com/vendasta/reputation/blob/master',
  'U-99e04759-35b8-4581-b131-5992365d956e': 'https://github.com/vendasta/sales/blob/master',
  'U-b237a4ef-fd8e-42b6-a516-ee4142df76e8': 'https://github.com/vendasta/partner/blob/master',
  'U-b0257997-7dbc-4f8e-94a6-9dce3bb06b8b': 'https://github.com/vendasta/campaigns/blob/master',
  'U-748d72e7-38b9-4b63-a036-1e3ae99135f4': 'https://github.com/vendasta/snapshot/blob/master',
  // Prod
  'U-5ba17e3e-1914-44b0-b54f-98a4b765e38c': 'https://github.com/vendasta/sales-orders/blob/master',
  'U-0c53679b-b79a-4275-9fcb-e8c0ded7cfa2': 'https://github.com/vendasta/developer-central/blob/master',
  'U-9c63d59d-81c9-4980-b917-68ac07994769': 'https://github.com/vendasta/business-center/blob/master',
  'U-13540457-6f8c-4da5-90f3-b089dde60566': 'https://github.com/vendasta/billing/blob/master',
  'U-7527d7fb-166c-4be2-a977-33c704ff8554': 'https://github.com/vendasta/reputation/blob/master',
  'U-855d447e-a2f4-4733-8497-fe7441d7b224': 'https://github.com/vendasta/sales/blob/master',
  'U-fbef6dd7-a81b-431f-b0d4-85ae422fdf5f': 'https://github.com/vendasta/partner/blob/master',
  'U-44c0e750-4d99-4eca-9a30-47dad60bc624': 'https://github.com/vendasta/campaigns/blob/master',
  'U-60878190-0b9e-4be4-a07a-1ca1cac92ec7': 'https://github.com/vendasta/snapshot/blob/master',
};
