import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomainStatusIconComponent } from './domain-status-icon.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [DomainStatusIconComponent],
  imports: [CommonModule, MatIconModule],
  exports: [DomainStatusIconComponent],
})
export class DomainStatusIconModule {}
