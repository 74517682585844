import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { PreferencesModule } from '@galaxy/email-ui/preferences';
import { GalaxyAlertModule } from '@vendasta/galaxy/alert';
import { GalaxyEmptyStateModule } from '@vendasta/galaxy/empty-state';
import { GalaxyWrapModule } from '@vendasta/galaxy/galaxy-wrap';
import { GalaxyPageModule } from '@vendasta/galaxy/page';
import { AsyncUiModule, EmptyStateModule } from '@vendasta/uikit';
import { DataProvidersModule } from '../../../data-providers';
import { EmailUsersComponent } from './email-users.component';
import { RecipientStatusComponent } from './recipient-status/recipient-status.component';

@NgModule({
  declarations: [EmailUsersComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    GalaxyWrapModule,
    MatCardModule,
    MatInputModule,
    GalaxyPageModule,
    GalaxyAlertModule,
    PreferencesModule,
    AsyncUiModule,
    EmptyStateModule,
    GalaxyEmptyStateModule,
    DataProvidersModule,
    RecipientStatusComponent,
  ],
  exports: [EmailUsersComponent],
  providers: [],
})
export class EmailUsersModule {}
